import {
  getLocalStorageItem,
  LOCAL_STORAGE_ITEMS,
  removeLocalStorageItem,
} from '@/utils/localStorage';

export const encodeQueryParams = (params: Record<string, string>) => {
  const queryString = new URLSearchParams(params).toString();
  return encodeURIComponent(queryString);
};

export const getFollowUpUrl = () => {
  const pathnameToFollow = getLocalStorageItem(
    LOCAL_STORAGE_ITEMS.POST_LOGIN_PATHNAME
  );

  if (!pathnameToFollow) {
    return null;
  }

  const queryParamsToAttach = getLocalStorageItem(
    LOCAL_STORAGE_ITEMS.POST_LOGIN_QUERY
  );

  let queryParams: string | null = null;
  if (queryParamsToAttach) {
    try {
      const jsonData = JSON.parse(queryParamsToAttach);
      queryParams = new URLSearchParams(jsonData).toString();
    } catch (error) {
      console.error('Error parsing query params', error);
    }
  }

  const redirectUrl = {
    pathname: pathnameToFollow,
    query: queryParams,
  };
  removeLocalStorageItem(LOCAL_STORAGE_ITEMS.POST_LOGIN_PATHNAME);
  removeLocalStorageItem(LOCAL_STORAGE_ITEMS.POST_LOGIN_QUERY);
  return redirectUrl;
};
