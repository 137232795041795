import { useEffect } from 'react';
import { Stack, Typography } from '@krakentech/coral';

import PaymentForecastTable from '@/components/billingAndPayment/PaymentForecastCard/components/PaymentForecastTable';
import PIITypography from '@/components/PII/PIITypography';
import { Body1Skeleton } from '@/components/Skeletons';
import { useDirectDebitContext } from '@/context';
import { PaymentPlanInstalmentState } from '@/context/DirectDebitContext/index.types';
import { useBalance } from '@/hooks/accounts/useBalance';
import { usePayInFullInstalments } from '@/hooks/billsAndPayments/usePayInFullInstalments';
import { formatCurrencyWithoutMinusSymbol } from '@/utils/formatters/currency';

import FixedScheduleError from '../FixedScheduleError';

const PayInFullContent = () => {
  const {
    data: payInFullInstalmentsData,
    isLoading: payInFullInstalmentsLoading,
    error: payInFullInstalmentsError,
    refetch: refetchPayInFullInstalments,
  } = usePayInFullInstalments();
  const {
    data: balance,
    isLoading: balanceLoading,
    error: balanceError,
    refetch: refetchBalance,
  } = useBalance();
  const {
    setPayInFullInstalments,
    setFirstPossibleVariableSchedulePaymentDate,
  } = useDirectDebitContext();

  const payInFullInstalments = payInFullInstalmentsData?.paymentAmounts.edges;

  useEffect(() => {
    setPayInFullInstalments(
      payInFullInstalments?.map(
        ({ node }: { node: PaymentPlanInstalmentState }) => ({
          ...node,
        })
      )
    );
  }, [payInFullInstalments, setPayInFullInstalments]);

  useEffect(() => {
    setPayInFullInstalments(
      payInFullInstalments?.map(
        ({ node }: { node: PaymentPlanInstalmentState }) => ({
          ...node,
        })
      )
    );
  }, [payInFullInstalments, setPayInFullInstalments]);

  useEffect(() => {
    setFirstPossibleVariableSchedulePaymentDate(
      payInFullInstalmentsData?.firstPossibleCollectionDate
    );
  }, [payInFullInstalmentsData, setFirstPossibleVariableSchedulePaymentDate]);

if (
    payInFullInstalmentsLoading ||
    balanceLoading ||
    !payInFullInstalments.length
  ) {
    return (
      <Stack direction="vertical" gap="md">
        <Body1Skeleton />

        <Stack fullWidth justifyContent="space-between" direction="vertical">
          <Stack>
            <Body1Skeleton />
            <Body1Skeleton />
          </Stack>
          <Stack>
            <Body1Skeleton />
            <Body1Skeleton />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (payInFullInstalmentsError || balanceError) {
    return (
      <FixedScheduleError
        refetch={() => {
          refetchBalance();
          refetchPayInFullInstalments();
        }}
      />
    );
  }

  return (
    <Stack direction="vertical" gap="md">
      <Typography>
        Your balance{' '}
        <PIITypography component="span">
          ({formatCurrencyWithoutMinusSymbol(balance)})
        </PIITypography>{' '}
        will be{' '}
        {payInFullInstalments.length === 1 ? (
          <span>paid in full</span>
        ) : (
          <span>
            split over <strong>2 instalments</strong>
          </span>
        )}
      </Typography>
      <PaymentForecastTable upcomingPayments={payInFullInstalments} />
    </Stack>
  );
};

export default PayInFullContent;
