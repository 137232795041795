import {
  Typography,
  useCoralBreakpoints,
  useCoralTheme,
} from '@krakentech/coral';
import { ActionCard as CoralActionCard } from '@krakentech/coral-molecules';
import {
  IconChevronRight,
  IconTriangleExclamationFilled,
} from '@krakentech/icons';

import { CircularSkeleton, H2Skeleton, H5Skeleton } from '../Skeletons';

import { StyledActionCard, StyledChevronRightIcon } from './index.styled';
import { ActionCardProps } from './index.types';

const ActionCard = ({
  title,
  subtitle,
  icon,
  href,
  isLoading = false,
  isError = false,
  titleComponent = 'h2',
  textAlign = 'left',
  showSubtitleSkeleton = false,
  openInNewTab = false,
  onClick,
}: ActionCardProps) => {
  const { color, spacing } = useCoralTheme();

  const { isMinMd } = useCoralBreakpoints();

  const cardTarget = openInNewTab ? '_blank' : '_self';

  const cardIcon = isError ? (
    <IconTriangleExclamationFilled size={32} />
  ) : isLoading ? (
    <CircularSkeleton width={spacing.lg} height={spacing.lg} />
  ) : (
    icon
  );

  const titleSkeletonWidth = isMinMd ? 300 : 150;

  const subtitleSkeletonWidth = isMinMd ? 400 : 180;

  const cardTitle = isError ? 'Unable to load data' : title;

  const cardSubtitle = isError ? 'Please try again later' : subtitle;

  const actionIconSize = isMinMd ? 20 : 18;

  const isNotErrorOrLoading = !isError && !isLoading;

  return (
    <StyledActionCard addRowGap={isLoading}>
      <CoralActionCard
        href={href}
        target={cardTarget}
        // Conditionally fire off onClick if href is undefined
        {...(!href && isNotErrorOrLoading && { onClick })}
        icon={cardIcon}
        actionIcon={
          <StyledChevronRightIcon displayIcon={isNotErrorOrLoading}>
            <IconChevronRight
              title="chevronRight"
              size={actionIconSize}
              color={color.primary.main}
            />
          </StyledChevronRightIcon>
        }
        theme="light"
      >
        {isLoading ? (
          <>
            <Typography>
              <H2Skeleton width={titleSkeletonWidth} />
            </Typography>

            {showSubtitleSkeleton && (
              <Typography>
                <H5Skeleton width={subtitleSkeletonWidth} />
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h2"
              component={titleComponent}
              textAlign={textAlign}
              textWrap="wrap"
            >
              {cardTitle}
            </Typography>
            <Typography textAlign={textAlign} color={'text-dark'}>
              {cardSubtitle}
            </Typography>
          </>
        )}
      </CoralActionCard>
    </StyledActionCard>
  );
};

export default ActionCard;
