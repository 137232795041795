import {
  UpcomingPayments,
  updatedPaymentScheduleCopy,
} from '@/components/billingAndPayment/UpcomingPaymentSchedule/helpers';
import PIITypography from '@/components/PII/PIITypography';
import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

type PaymentScheduleCopyProps = {
  paymentScheduleDetails: {
    validFrom: string;
    isVariablePaymentAmount: boolean;
    isFinalPayment: boolean;
    finalPaymentDay?: number;
    finalPaymentMonth?: string;
    paymentAmount: number;
    paymentFrequency: PAYMENT_FREQUENCY;
    paymentFrequencyMultiplier: number;
    paymentDay: number;
    upcomingPayments?: UpcomingPayments;
  };
};

const PaymentScheduleCopy = ({
  paymentScheduleDetails,
}: PaymentScheduleCopyProps) => {
  const {
    validFrom,
    isVariablePaymentAmount,
    paymentAmount,
    paymentFrequency,
    paymentFrequencyMultiplier,
    paymentDay,
  } = paymentScheduleDetails;

  const changePaymentScheduleEnabled = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );

  const originalPaymentScheduleCopy = (
    <PIITypography>
      After <strong>{formatFullDateShortMonth(new Date(validFrom))}</strong>,
      you&apos;ll be paying{' '}
      {isVariablePaymentAmount ? (
        <strong>when you get your bill</strong>
      ) : (
        <strong>
          {formatCurrency(paymentAmount)} every{' '}
          {getPaymentFrequency(paymentFrequency, paymentFrequencyMultiplier)}
          {paymentFrequency === PAYMENT_FREQUENCY.MONTHLY &&
            ` on the ${cardinalNumberToOrdinalNumber(paymentDay)}`}
        </strong>
      )}
    </PIITypography>
  );

  const updatedPaymentScheduleCopyComponent = (
    <PIITypography>
      {updatedPaymentScheduleCopy(paymentScheduleDetails)}
    </PIITypography>
  );

  return changePaymentScheduleEnabled
    ? updatedPaymentScheduleCopyComponent
    : originalPaymentScheduleCopy;
};

export default PaymentScheduleCopy;
