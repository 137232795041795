import { Grid } from '@krakentech/coral';
import { IconCircleGBP } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { DIRECT_DEBIT_INSTRUCTION_STATUS } from '@/consts/directDebit';
import { INTERNAL_PATHS } from '@/utils/urls';

type ManagePaymentsCardProps = {
  isLoading: boolean;
  isError: boolean;
  DDInstructionType: DIRECT_DEBIT_INSTRUCTION_STATUS | null;
};

const ManagePaymentsCard = ({
  isLoading,
  isError,
  DDInstructionType,
}: ManagePaymentsCardProps) => {
  const href = INTERNAL_PATHS.MANAGE_PAYMENTS.path;

  const hasValidDDInstruction =
    DDInstructionType === DIRECT_DEBIT_INSTRUCTION_STATUS.PROVISIONAL ||
    DDInstructionType === DIRECT_DEBIT_INSTRUCTION_STATUS.ACTIVE;

  // Do not render if the DDInstructionType is not PROVISIONAL or ACTIVE
  if (!hasValidDDInstruction) {
    return null;
  }

  return (
    <Grid.Item>
      <ActionCard
        href={href}
        /*
          NOTE: Although we can only currently manage DD via the consumer site, there is the possibility this could extend to other methods.
          Therefore, it makes sense to keep references to 'manage payments' e.g 'ManagePaymentsCard' intact and not change all naming conventions/routes/components towards 'manage direct debit'.
          At some point in time we can make this title copy dynamic.
        */
        title="Manage direct debit"
        titleComponent="p"
        icon={<IconCircleGBP size={32} />}
        isLoading={isLoading}
        isError={isError}
      />
    </Grid.Item>
  );
};

export default ManagePaymentsCard;
