import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { commsAndMarketingPreferencesSchema } from '@/hooks/accounts/validations';

import CommsAndMarketingPreferences from './CommsAndMarketingPreferences.graphql';

export const useCommsAndMarketingPreferences = () => {
  const { isAuthenticated } = useAuth();

  const { accountNumber } = useAccountNumberContext();

  return useKrakenQuery({
    queryKey: ['commsAndMarketingPreferences'],
    query: CommsAndMarketingPreferences,
    select: (data: any) => data,
    variables: { accountNumber },
    validateFn(response) {
      return commsAndMarketingPreferencesSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
    cacheTime: 0,
  });
};
