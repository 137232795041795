import { RefAttributes } from 'react';
import {
  FormikTextField,
  FormikTextFieldProps,
} from '@krakentech/coral-formik';

import { piiDataAttributes } from '@/components/PII/constants';

const PIIFormikTextField = (
  props: FormikTextFieldProps & RefAttributes<HTMLInputElement>
) => {
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // Call onFocus from props if it exists
    if (props.onFocus) {
      props.onFocus(e);
    }

    if (props.type === 'number') {
      const preventScroll = (event: WheelEvent) => event.preventDefault();
      e.target.addEventListener('wheel', preventScroll, { passive: false });

      // Cleanup on blur
      e.target.addEventListener(
        'blur',
        () => {
          e.target.removeEventListener('wheel', preventScroll);
        },
        { once: true }
      );
    }
  };

  return (
    <FormikTextField
      {...props}
      attributes={{
        ...props.attributes,
        ...piiDataAttributes,
      }}
      onFocus={handleFocus}
    />
  );
};

export default PIIFormikTextField;
