import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { getCorrectLedgerId } from '@/utils/ledgers';

import { useLedgers } from '../useLedgers';
import { paymentForecastSchema } from '../validations';

import { UsePaymentForecastArgs } from './index.types';
import PaymentForecast from './PaymentForecast.graphql';

export const usePaymentForecast = ({ first }: UsePaymentForecastArgs) => {
  const { isAuthenticated } = useAuth();
  const { accountNumber } = useAccountNumberContext();

  const { data: ledgerData } = useLedgers();

  const ledgerId = getCorrectLedgerId(ledgerData);

  return useKrakenQuery({
    queryKey: ['paymentForecast'],
    query: PaymentForecast,
    select: (data: any) => data.account.paginatedPaymentForecast.edges,
    variables: { accountNumber, ledgerId, first },
    validateFn(response) {
      return paymentForecastSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber && ledgerId && first),
    cacheTime: 0,
    refetchOnMount: 'always',
  });
};
