import { Button } from '@krakentech/coral';
import styled, { css } from 'styled-components';

export const StyledIconDropdownContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: ${theme.borderRadius};
    cursor: pointer;
  `}
`;

// @ts-expect-error - this TS error has popped up since the Next 13 upgrade, and shouldn't be happning because Button is a styled component. Would need to investigate further.
export const StyledIconDropdownButton = styled(Button)`
  ${({ theme }) => css`
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${theme.borderWidth};
    }
  `}
`;

export const Panel = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + 14px);
    right: 0;
    border: 1px solid ${theme.color.secondary.main10};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.borderRadius};
    z-index: 1000;
    padding: 8px 8px;
    display: inline-block;
    min-width: max-content;
    background-color: ${theme.color.base.dark};
  `}
`;

// @ts-expect-error - this TS error has popped up since the Next 13 upgrade, and shouldn't be happning because Button is a styled component. Would need to investigate further.
export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: flex-start;
    min-width: max-content;
    padding-right: ${theme.spacing.md};

    &:focus {
      text-decoration: none;
      outline: none;
      border-color: transparent;
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${theme.borderWidth};
    }
  `}
`;
