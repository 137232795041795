import { Card, Stack, Typography } from '@krakentech/coral';

import { MutedText } from '@/components';
import UpcomingPaymentSchedule from '@/components/billingAndPayment/UpcomingPaymentSchedule';

import PIITypography from '../PII/PIITypography';

import { PaymentInfoCardProps } from './index.types';

const PaymentInfoCard = ({
  header,
  amount,
  explanation,
  dataAttributes,
  isFullHeight,
  showUpcomingPaymentSchedule,
}: PaymentInfoCardProps) => (
  <Card {...(dataAttributes && { dataAttributes })} fullHeight={isFullHeight}>
    <Card.Body>
      <Stack direction="vertical" gap="smMd" alignItems="center">
        <Typography component="h2" variant="h2">
          {header}
        </Typography>

        <Stack direction="vertical" gap="xsSm" alignItems="center">
          <PIITypography component="div" variant="homepageTitle">
            {amount}
          </PIITypography>

          {explanation && (
            <MutedText textAlign="center" containsPII>
              {explanation}
            </MutedText>
          )}
          {showUpcomingPaymentSchedule && <UpcomingPaymentSchedule />}
        </Stack>
      </Stack>
    </Card.Body>
  </Card>
);

export default PaymentInfoCard;
