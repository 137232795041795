export enum PAYMENT_FREQUENCY {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  PLANNED = 'Planned',
}

export enum PAYMENT_FREQUENCY_FIELD_OPTIONS {
  VARIABLE = 'variable',
  MONTHLY = 'monthly',
  BIANNUAL_INSTALMENTS = 'biannualInstalments',
}

export enum DIRECT_DEBIT_SCHEDULE_CHOICES {
  MONTHLY = 'MONTHLY',
  PAY_ON_RECEIPT_OF_BILL = 'PAY_ON_RECEIPT_OF_BILL',
  BIANNUAL_INSTALMENTS = 'BIANNUAL_INSTALMENTS',
}
