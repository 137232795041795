import { useRouter } from 'next/router';
import { Card, Stack, Typography } from '@krakentech/coral';
import { useFormikContext } from 'formik';

import EstimatedInstalmentsTable from '@/components/billingAndPayment/EstimatedPaymentsCard/components/EstimatedInstalmentsTable';
import { H2Skeleton } from '@/components/Skeletons';
import { PAYMENT_FREQUENCY_FIELD_OPTIONS } from '@/consts/paymentFrequency';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import { useIsOnMeasuredTariff } from '@/hooks/usage/useIsOnMeasuredTariff';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { PaymentFrequencyFormValues } from '@/types/directDebit';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

import {
  FixedMonthlyScheduleContent,
  PaymentPlanContent,
  VariableScheduleContent,
  PayInFullContent,
  SetUpDDLater,
} from './components';
import FixedScheduleError from './components/FixedScheduleError';
import PaymentCardLoader from './components/PaymentCardLoader';

const EstimatedPaymentsCard = () => {
  const { pathname } = useRouter();

  const newEditDDUnmeasuredUIEnabled = useSyncFeatureFlag(
    FeatureNames.NewEditDirectDebitUnmeasuredUI
  );

  const {
    data: activeWaterMeterCount,
    isLoading,
    isError,
    refetch,
  } = useHasActiveWaterMeter();
  const { data: isMetered } = useIsOnMeasuredTariff();
  const { data: balance } = useBalance();

  const { values } = useFormikContext<PaymentFrequencyFormValues>();

  const changingPaymentSchedule = pathname.includes(
    INTERNAL_PATHS.CHANGE_PAYMENT_SCHEDULE.path
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError) {
    return <FixedScheduleError refetch={refetch} />;
  }

  return (
    <Card>
      <Stack direction="vertical" gap="md">
        <Typography variant="h2">Your new schedule</Typography>

        {values.paymentFrequency ===
          PAYMENT_FREQUENCY_FIELD_OPTIONS.BIANNUAL_INSTALMENTS && (
          <>{balance >= 0 ? <SetUpDDLater /> : <PayInFullContent />}</>
        )}

        {values.paymentFrequency ===
          PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE && (
          <VariableScheduleContent />
        )}

        {values.paymentFrequency ===
          PAYMENT_FREQUENCY_FIELD_OPTIONS.MONTHLY && (
          <>
            {activeWaterMeterCount && isMetered ? (
              <FixedMonthlyScheduleContent />
            ) : newEditDDUnmeasuredUIEnabled && changingPaymentSchedule ? (
              <EstimatedInstalmentsTable />
            ) : (
              <PaymentPlanContent />
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

const LoadingState = () => (
  <Card>
    <Stack direction="vertical" gap="md">
      <H2Skeleton />

      <PaymentCardLoader title="Next payment" />
    </Stack>
  </Card>
);

export default EstimatedPaymentsCard;
