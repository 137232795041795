import { array, boolean, type InferType, number, object, string } from 'yup';

import { COMMS_DELIVERY_PREFERENCES } from '@/consts/commsDeliveryPreferences';
import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';

const detailsSchema = array(
  object({
    namespace: string(),
    value: string().nullable(),
  })
);

export const accountNumberListSuccessSchema = object({
  viewer: object({
    accounts: array(
      object({
        number: string().required(),
      })
    ),
  }),
});
export type TAccountNumberList = InferType<
  typeof accountNumberListSuccessSchema
>;

export const personalDetailsSuccessSchema = object({
  viewer: object({
    id: string(),
    title: string().nullable(),
    givenName: string(),
    familyName: string(),
    email: string(),
    mobile: string(),
    pronouns: string().nullable(),
    landline: string().nullable(),
    dateOfBirth: string().nullable(),
    details: detailsSchema,
  }),
}).required();

export const billingAddressSuccessSchema = object({
  account: object({
    id: string(),
    billingAddressLine1: string().required(),
    billingAddressLine2: string(),
    billingAddressLine3: string(),
    billingAddressLine4: string(),
    billingAddressLine5: string(),
    billingAddressPostcode: string().required(),
    splitBillingAddress: array(string()),
    properties: array(
      object({
        id: string(),
        splitAddress: array(string()),
        postcode: string(),
      })
    ),
  }),
}).required();

export const fullNameAndSupplyAddressSuccessSchema = object({
  viewer: object({
    title: string().nullable(),
    fullName: string(),
  }).required(),
  account: object({
    properties: array(
      object({
        id: string(),
        address: string(),
      }).required()
    ).required(),
  }).required(),
});

const settingsPageSchema = object({
  viewer: object({
    id: string(),
    fullName: string(),
    email: string(),
    mobile: string().nullable(),
    accounts: array(
      object({
        billingAddress: string(),
      })
    ),
    pronouns: string().nullable(),
    landline: string().nullable(),
    dateOfBirth: string().nullable(),
    details: detailsSchema,
  }),
});

export const updatePasswordSchema = object({
  updatePassword: settingsPageSchema,
});

export const updateUserSchema = object({
  updateUser: settingsPageSchema,
});

export const updateUserMobileNumberSchema = object({
  updateUser: object({
    viewer: object({
      mobile: string().required(),
      email: string().required(),
      id: string().required(),
    }),
  }),
});

const commsPreferences = object({
  [MARKETING_PREFERENCE_OPTIONS.SMS]: boolean().required(),
  [MARKETING_PREFERENCE_OPTIONS.MARKETING]: boolean().required(),
  [MARKETING_PREFERENCE_OPTIONS.PLUMBING_OFFER]: boolean().required(),
});

export const commsAndMarketingPreferencesSchema = object({
  viewer: object({
    email: string().required(),
    mobile: string().optional(),
    commsPreferences,
  }),
  account: object({
    billingAddressPostcode: string().required(),
    commsDeliveryPreference: string()
      .oneOf(Object.values(COMMS_DELIVERY_PREFERENCES))
      .required(),
  }),
});

export const updateCommsDeliveryPreferenceSchema = object({
  updateCommsDeliveryPreference: object({
    account: object({
      commsDeliveryPreference: string()
        .oneOf(Object.values(COMMS_DELIVERY_PREFERENCES))
        .required(),
    }),
  }),
});

export const updateCommsPreferencesSchema = object({
  updateCommsPreferences: object({
    commsPreferences,
  }),
});

export const viewerEmailSuccessSchema = object({
  viewer: object({
    email: string().required(),
  }),
});

export const viewerIdSuccessSchema = object({
  viewer: object({
    id: string().required(),
  }),
});

export const balanceSuccessSchema = object({
  account: object({
    balance: number(),
  }),
}).required();

export const psrRecordTypeSchema = object({
  psrRecordType: array(
    object({
      description: string().required(),
      extraInfoFields: array(string()).nullable(),
      id: string().required(),
      internalCode: string().required(),
      isTemporary: boolean().nullable(),
    })
  ).required(),
});

export const updateUserPsrRecordsSchema = object({
  updateUserPsrRecords: object({
    psrRecords: array(
      object({
        id: string(),
        expiryDate: string().nullable(),
        extraInfo: array(
          object({
            name: string(),
            value: string(),
          })
        ),
      })
    ),
  }),
});

export const specialCircumstancesSuccessSchema = object({
  viewer: object({
    specialCircumstances: object({
      records: array(
        object({
          description: string(),
          expiryDate: string().nullable(),
          extraInfo: array(
            object({
              name: string(),
              value: string(),
            })
          ),
          id: string(),
          internalCode: string(),
          summary: string(),
        })
      ),
    }),
  }),
});

export const psrRenewalDueSchema = object({
  viewer: object({
    isPsrRenewalDue: boolean().required(),
  }),
});

export const campaignsSchema = object({
  account: object({
    campaigns: array(
      object({
        name: string().nullable(),
        slug: string().nullable(),
        startDate: string().nullable(),
        expiryDate: string().nullable(),
      })
    ).required(),
  }),
});

export const hasActiveDebtCollectionProceedingSchema = object({
  account: object({
    hasActiveDebtCollectionProceeding: boolean().required(),
  }),
});

export const hasActiveSocialAgreementSchema = object({
  account: object({
    hasActiveSocialAgreement: boolean().required(),
  }),
});

export const hasActiveWatersureAgreementSchema = object({
  account: object({
    hasActiveWatersureAgreement: boolean().required(),
  }),
});

export const hasActiveAssessedAgreementSchema = object({
  account: object({
    hasActiveAssessedAgreement: boolean().required(),
  }),
});

export const updatePropertyTypeSchema = object({
  updatePropertyType: object({
    propertyId: string().required(),
  }),
});

export const updateNumberOfOccupantsSchema = object({
  updateNumberOfOccupants: object({
    propertyId: string().required(),
  }),
});

export const initialPaymentEstimateSchema = object({
  initialPaymentEstimate: object({
    paymentAmount: number().required(),
    paymentFrequency: string().required(),
    paymentFrequencyMultiplier: number().required(),
  }),
});

export const portfolioAccountsSchema = object({
  viewer: object({
    accounts: array(
      object({
        number: string().required(),
        balance: number().required(),
        address: object({
          streetAddress: string().required(),
          postalCode: string().required(),
        }).required(),
      })
    ).required(),
  }),
});
