import { FC, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Loader } from '@krakentech/coral';

import BreadcrumbHeader from '@/components/BreadcrumbHeader';
import { useAuth } from '@/components/Providers/Auth';
import { AccountNumberContextProvider } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import CustomHead from '@/pages/_head.page';
import { LOCAL_STORAGE_ITEMS, setLocalStorageItem } from '@/utils/localStorage';
import { INTERNAL_PATHS } from '@/utils/urls';

import { DesktopNav, MobileNav } from './components';

const AuthenticatedPageLayout: FC<{
  pageTitle?: string;
  children: ReactNode;
}> = ({ pageTitle, children }) => {
  const { isAuthenticated, hasLoaded } = useAuth();
  const { replace, isReady, pathname, query, events } = useRouter();
  const [headerHeight, setHeaderHeight] = useState(64);

  const [shouldRedirect, setShouldRedirect] = useState(true);

  // Measure header height to position portfoliobreadcrumb header below the main header
  useEffect(() => {
    const measureHeader = () => {
      const headerElement = document.querySelector('header');
      if (headerElement) {
        const height = headerElement.getBoundingClientRect().height;
        // If we're still loading, add the loader height (4px) to the header height
        setHeaderHeight(hasLoaded ? height : height + 4);
      }
    };

    measureHeader();

    // `We need to remeasure on window resize
    window.addEventListener('resize', measureHeader);

    return () => window.removeEventListener('resize', measureHeader);
  }, [hasLoaded]);

  // This is not great.
  // There are multiple places in the codebase where we redirect users
  // to the login page, especially after the logout button is clicked.
  // This effect to prevent:
  // 1. a double redirection to the login page when the user becomes unauthenticated
  // 2. the current path from being stored in local storage when the user becomes unauthenticated
  useEffect(() => {
    const handleRouteChange = () => {
      setShouldRedirect(false);
    };
    const handleRouteComplete = () => {
      setShouldRedirect(true);
    };

    events.on('routeChangeStart', handleRouteChange);
    events.on('routeChangeComplete', handleRouteComplete);

    return () => {
      events.off('routeChangeStart', handleRouteChange);
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  if (!hasLoaded) {
    return <Loader type="linear" variant="indeterminate" />;
  }

  // If the user is not authenticated, redirect to the login page.
  // Purposefully excluding the case of the user is logging out, as it already
  // redirects to the login page, and as we don't want to store the current
  // path in local storage in that case.
  if (!isAuthenticated && isReady && shouldRedirect) {
    setLocalStorageItem(LOCAL_STORAGE_ITEMS.POST_LOGIN_PATHNAME, pathname);
    setLocalStorageItem(
      LOCAL_STORAGE_ITEMS.POST_LOGIN_QUERY,
      JSON.stringify(query)
    );
    replace(INTERNAL_PATHS.LOGIN.path);
    return null;
  }

  return (
    <AccountNumberContextProvider>
      <CustomHead title={pageTitle} />
      <DesktopNav />
      <BreadcrumbHeader headerHeight={headerHeight} />
      {children}
      <MobileNav />
    </AccountNumberContextProvider>
  );
};

export default AuthenticatedPageLayout;
