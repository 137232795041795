import { Button, Stack, useCoralBreakpoints } from '@krakentech/coral';

import SetUpDirectDebitCta from '@/components/billingAndPayment/PaymentPlanCard/SetUpDirectDebitCta';
import BoxWithGreyBorder from '@/components/BoxWithGreyBorder';
import PIITypography from '@/components/PII/PIITypography';
import { DIRECT_DEBIT_INSTRUCTION_STATUS } from '@/consts/directDebit';
import { useCurrentActiveDirectDebitInstruction } from '@/hooks/billsAndPayments/useCurrentActiveDirectDebitInstruction';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { addHyphensIntoSortCode } from '@/utils/formatters/sortCode';

const BankDetailsCard = ({
  changeButtonHref,
}: {
  changeButtonHref?: string;
}) => {
  const { data } = useCurrentActiveDirectDebitInstruction();
  const changeBankDetailsEnabled = useSyncFeatureFlag(
    FeatureNames.ChangeBankDetails
  );
  const { isMaxMd } = useCoralBreakpoints();

  if (typeof data === 'undefined' || !data?.node?.id) {
    return <SetUpDirectDebitCta />;
  } else {
    const { accountHolder, sortCode, maskedAccountIdentifier, status } =
      data.node;

    const isProvisionalDirectDebit =
      status === DIRECT_DEBIT_INSTRUCTION_STATUS.PROVISIONAL;

    const showChangeButton =
      changeBankDetailsEnabled && changeButtonHref && !isProvisionalDirectDebit;

    return (
      <>
        <BoxWithGreyBorder testId="bankDetailsCard" darkBackground>
          <Stack
            direction="vertical"
            justifyContent="space-between"
            alignItems="center"
            gap="sm"
            md={{
              direction: 'horizontal',
              gap: 'md',
            }}
          >
            <Stack
              direction="vertical"
              gap="sm"
              {...(isMaxMd && { alignItems: 'center' })}
            >
              <PIITypography>{accountHolder}</PIITypography>

              <Stack gap="md">
                <PIITypography variant="body2">
                  {maskedAccountIdentifier}{' '}
                </PIITypography>
                <PIITypography variant="body2">
                  {addHyphensIntoSortCode(sortCode)}
                </PIITypography>
              </Stack>
            </Stack>
          </Stack>
        </BoxWithGreyBorder>
        {showChangeButton && (
          <Button variant="outlined" href={changeButtonHref} fullWidth={true}>
            Change your bank details
          </Button>
        )}
      </>
    );
  }
};

export default BankDetailsCard;
