import { addDays, format, getDate } from 'date-fns';

import { PAYMENT_FREQUENCY } from '@/consts/paymentFrequency';
import { formatCurrency } from '@/utils/formatters/currency';
import { makeDateKrakenFriendly } from '@/utils/formatters/date';
import { cardinalNumberToOrdinalNumber } from '@/utils/formatters/number';
import { getPaymentFrequency } from '@/utils/paymentSchedule';

export type PaymentScheduleDetails = {
  isVariablePaymentAmount: boolean;
  isFinalPayment: boolean;
  finalPaymentDay?: number;
  finalPaymentMonth?: string;
  paymentAmount: number;
  paymentFrequency: PAYMENT_FREQUENCY;
  paymentFrequencyMultiplier: number;
  paymentDay: number;
  upcomingPayments?: UpcomingPayments;
};

type PaymentNode = {
  date: string;
};

type PaymentEdge = {
  node: PaymentNode;
};

export type UpcomingPayments = PaymentEdge[];

// Use `makeDateKrakenFriendly` to ensure `activeOnDate` is in the correct format
export const getActiveOnDate = (validToDate: string | null | undefined) =>
  validToDate && !isNaN(new Date(validToDate).getTime())
    ? makeDateKrakenFriendly(addDays(new Date(validToDate), 1))
    : undefined;

export const getFinalPaymentDetails = (upcomingPayments: UpcomingPayments) => {
  const isFinalPayment = upcomingPayments?.length === 1;
  const finalPaymentDate = upcomingPayments?.[0]?.node?.date;

  const finalPaymentDay = finalPaymentDate
    ? getDate(new Date(finalPaymentDate))
    : undefined;

  const finalPaymentMonth = finalPaymentDate
    ? format(new Date(finalPaymentDate), 'MMMM')
    : undefined;

  return { isFinalPayment, finalPaymentDay, finalPaymentMonth };
};

export const updatedPaymentScheduleCopy = ({
  isVariablePaymentAmount,
  isFinalPayment,
  finalPaymentDay,
  finalPaymentMonth,
  paymentAmount,
  paymentFrequency,
  paymentFrequencyMultiplier,
  paymentDay,
  upcomingPayments,
}: PaymentScheduleDetails): string => {
  const common = `We're processing your payment change. Once completed, you'll `;
  const numberOfPayments = upcomingPayments?.length || 0;
  if (isVariablePaymentAmount) {
    return `${common}switch to a variable plan and pay when you get your bill.`;
  }
  if (isFinalPayment && finalPaymentDay && finalPaymentMonth) {
    return `${common}pay ${formatCurrency(
      paymentAmount
    )} on the ${cardinalNumberToOrdinalNumber(
      finalPaymentDay
    )} ${finalPaymentMonth}.`;
  }
  if (paymentFrequency === PAYMENT_FREQUENCY.PLANNED && numberOfPayments >= 2) {
    return `${common}pay ${numberOfPayments} instalments of ${formatCurrency(
      paymentAmount
    )}.`;
  }
  if (paymentFrequency === PAYMENT_FREQUENCY.MONTHLY) {
    return `${common}start paying ${formatCurrency(
      paymentAmount
    )} every ${getPaymentFrequency(
      paymentFrequency,
      paymentFrequencyMultiplier
    )} on the ${cardinalNumberToOrdinalNumber(paymentDay)}.`;
  }
  return `${common}start paying ${formatCurrency(
    paymentAmount
  )} every ${getPaymentFrequency(
    paymentFrequency,
    paymentFrequencyMultiplier
  )}`;
};
