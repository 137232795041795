import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import {
  Stack,
  Typography,
  ButtonVariant,
  ButtonSize,
  ButtonColor,
} from '@krakentech/coral';

import {
  Panel,
  StyledButton,
  StyledIconDropdownButton,
  StyledIconDropdownContainer,
} from './index.styled';

export type DropdownItem = {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
};

type IconDropdownProps = {
  items: DropdownItem[];
  /**
   * Icon to display when the dropdown is closed
   * This is required and will be used as the default icon
   */
  closedIcon: ReactNode;
  /**
   * Optional icon to display when the dropdown is open
   * If not provided, the closedIcon will be used instead
   */
  openIcon?: ReactNode;
  /**
   * Optional variant for the buttons
   * This affects the button that opens/closes the dropdown, and the menu items
   * @default "contained"
   */
  variant?: ButtonVariant;
  /**
   * Optional colors for the button
   * This affects the button that opens/closes the dropdown, and the menu items
   * @default "primary"
   */
  color?: ButtonColor;
  /**
   * Optional size for the buttons
   * This affects the button that opens/closes the dropdown, and the menu items
   * @default "medium"
   */
  size?: ButtonSize;
};

const IconDropdown: FC<PropsWithChildren<IconDropdownProps>> = ({
  items,
  closedIcon,
  openIcon,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLButtonElement | HTMLAnchorElement | null)[]>([]);

  // Use the openIcon if provided, otherwise fall back to the closedIcon
  const currentIcon = dropdownOpen && openIcon ? openIcon : closedIcon;

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    setFocusedItemIndex(0);
  };

  const handleCustomInteraction = (
    event: ReactMouseEvent | ReactKeyboardEvent
  ) => {
    const isClick = event.type === 'click';
    const isKeydown = event.type === 'keydown';

    if (isClick) {
      event.preventDefault();
      toggleDropdown();
      return;
    }

    if (isKeydown) {
      const keyEvent = event as ReactKeyboardEvent;
      const { key } = keyEvent;

      if (key === 'Enter' || key === ' ') {
        keyEvent.preventDefault();
        toggleDropdown();
      } else if (key === 'ArrowDown' || key === 'ArrowUp') {
        keyEvent.preventDefault();
        setDropdownOpen(true);
        setFocusedItemIndex((prevIndex) => {
          const increment = key === 'ArrowDown' ? 1 : -1;
          return (prevIndex + increment + items.length) % items.length;
        });
      }
    }
  };

  /*
    Handles clicks outside the dropdown to close it unless the click is within
    the dropdown container or the panel. This prevents the dropdown from closing
    when interacting with elements within the dropdown.
   */
  const handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    if (
      containerRef.current?.contains(targetElement) ||
      panelRef.current?.contains(targetElement)
    ) {
      return;
    }
    setDropdownOpen(false);
  };

  const handleItemBlur = () => {
    requestAnimationFrame(() => {
      if (
        containerRef.current &&
        !containerRef.current.contains(document.activeElement) &&
        !panelRef.current?.contains(document.activeElement)
      ) {
        setDropdownOpen(false);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      // Automatically shift focus to the first dropdown element when the dropdown opens.
      requestAnimationFrame(() => {
        if (itemRefs.current[0]) {
          itemRefs.current[0].focus();
        }
      });
    }
  }, [dropdownOpen]);

  const handleItemClick = (childOnClick: () => void) => {
    setDropdownOpen(false);
    childOnClick();
  };

  const handleItemKeyDown = (
    event: ReactKeyboardEvent,
    childOnClick: () => void
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleItemClick(childOnClick);
    } else if (event.key === 'Escape') {
      setDropdownOpen(false);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedItemIndex(
        (prevIndex) => (prevIndex - 1 + items.length) % items.length
      );
    }
  };

  return (
    <div>
      <StyledIconDropdownContainer aria-label="More options" ref={containerRef}>
        <StyledIconDropdownButton
          aria-label="Toggle dropdown menu"
          aria-expanded={dropdownOpen}
          aria-haspopup="menu"
          size={size}
          variant={variant}
          color={color}
          onClick={handleCustomInteraction}
          tabIndex={0}
          type="button"
        >
          {currentIcon}
        </StyledIconDropdownButton>
        {dropdownOpen && (
          <Panel role="menu" aria-label="Dropdown menu" ref={panelRef}>
            <Stack
              alignItems="stretch"
              gap="xxs"
              direction="vertical"
              fullWidth
            >
              {items?.map((item, index) => (
                <StyledButton
                  key={item.label}
                  onClick={() => {
                    handleItemClick(item.onClick);
                  }}
                  onKeyDown={(event: ReactKeyboardEvent<HTMLButtonElement>) =>
                    handleItemKeyDown(event, item.onClick)
                  }
                  onBlur={() => handleItemBlur()}
                  startIcon={item.icon}
                  color={color}
                  variant={variant}
                  tabIndex={index === focusedItemIndex ? 0 : -1}
                  ref={(element: any) => (itemRefs.current[index] = element)}
                  role="menuitem"
                  fullWidth
                  size={size}
                >
                  <Typography variant="h3" whiteSpace="nowrap">
                    {item.label}
                  </Typography>
                </StyledButton>
              ))}
            </Stack>
          </Panel>
        )}
      </StyledIconDropdownContainer>
    </div>
  );
};

export default IconDropdown;
