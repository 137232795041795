import { useEffect } from 'react';
import { Stack } from '@krakentech/coral';
import { useFormikContext } from 'formik';

import { Body1Skeleton, H4Skeleton } from '@/components';
import FixedScheduleError from '@/components/billingAndPayment/EstimatedPaymentsCard/components/FixedScheduleError';
import PaymentForecastTable from '@/components/billingAndPayment/PaymentForecastCard/components/PaymentForecastTable';
import PIITypography from '@/components/PII/PIITypography';
import { PAYMENT_PLAN_BILLING_CONFIG } from '@/consts/directDebit';
import {
  DIRECT_DEBIT_SCHEDULE_CHOICES,
  PAYMENT_FREQUENCY_FIELD_OPTIONS,
} from '@/consts/paymentFrequency';
import { useDirectDebitContext } from '@/context';
import { useBalance } from '@/hooks/accounts/useBalance';
import { useAllPaymentPlanInstalment } from '@/hooks/billsAndPayments/useAllPaymentPlanInstalment';
import { useProposedDirectDebitPaymentSchedule } from '@/hooks/billsAndPayments/useProposedDirectDebitPaymentSchedule';
import { PaymentFrequencyFormValues } from '@/types/directDebit';
import { getNextNthDayOfTheMonth } from '@/utils/date';
import { formatCurrencyWithCreditOrDebitIndicator } from '@/utils/formatters/currency';
import { formatDateMonthYear } from '@/utils/formatters/date';

const EstimatedInstalmentsTable = () => {
  const { values } = useFormikContext<PaymentFrequencyFormValues>();
  const {
    setFirstPossibleFixedSchedulePaymentDate,
    firstPossibleFixedSchedulePaymentDate,
    setPaymentPlanFirstInstalment,
    setPaymentPlanLastInstalment,
  } = useDirectDebitContext();

  // Calling the useProposedDirectDebitPaymentSchedule hook to get the first possible collection date
  const {
    data,
    isLoading: isLoadingProposedPaymentSchedule,
    isError: isErrorProposedPaymentSchedule,
  } = useProposedDirectDebitPaymentSchedule({
    scheduleType:
      typeof values.paymentFrequency !== 'undefined' &&
      values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE
        ? DIRECT_DEBIT_SCHEDULE_CHOICES.PAY_ON_RECEIPT_OF_BILL
        : DIRECT_DEBIT_SCHEDULE_CHOICES.MONTHLY,
  });

  const firstPossibleCollectionDate = data?.firstPossibleCollectionDate;

  const {
    isLoading: isLoadingInstalments,
    isError: isErrorInstalments,
    data: instalments,
    refetch: refetchInstalments,
  } = useAllPaymentPlanInstalment({
    firstPreferredPaymentDate:
      values.paymentDay?.value && firstPossibleCollectionDate
        ? getNextNthDayOfTheMonth(
            values.paymentDay?.value,
            firstPossibleCollectionDate
          )
        : '',
  });

  const {
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
    data: balance,
  } = useBalance();

  // If the last instalment is the first payment (meaning it has a paymentNumber of 1), then it's the same instalment as the first one. So we don't display it. The reason this happens is if the user wants to set up a payment plan at the end of the financial year, so we only have one payment left for them in the current financial year
  const notAtEndOfFinancialYear = instalments?.length !== 1;
  const atEndOfFinancialYear = !notAtEndOfFinancialYear;

  useEffect(() => {
    if (instalments && instalments.length && values.paymentDay) {
      // Get what the user's next desired payment date is, based off their selected payment day. E.g. if today is January 1st 2024 and they want to pay on the 5th of each month, their nextDesiredPaymentDate is January 5th 2024
      const nextDesiredPaymentDate = getNextNthDayOfTheMonth(
        values.paymentDay.value,
        new Date(instalments[0].node.date),
        atEndOfFinancialYear
      );

      // Save this value to local state
      setFirstPossibleFixedSchedulePaymentDate(nextDesiredPaymentDate);
    }
  }, [
    instalments,
    values.paymentDay,
    setFirstPossibleFixedSchedulePaymentDate,
    atEndOfFinancialYear,
  ]);

  // Save first instalment amount and date to context to be used in the payment plan summary on the next page
  useEffect(() => {
    if (
      instalments &&
      instalments.length &&
      firstPossibleFixedSchedulePaymentDate
    ) {
      setPaymentPlanFirstInstalment({
        type: 'SET_INSTALMENT',
        instalment: {
          amount: instalments[0].node.amount,
          date: new Date(firstPossibleFixedSchedulePaymentDate),
          paymentNumber: instalments[0].node.paymentNumber,
        },
      });
    }
  }, [
    instalments,
    firstPossibleFixedSchedulePaymentDate,
    setPaymentPlanFirstInstalment,
  ]);

  // Save last instalment amount, date and paymentNumber to context to be used in the payment plan summary on the next page
  useEffect(() => {
    if (instalments && instalments.length) {
      setPaymentPlanLastInstalment({
        type: 'SET_INSTALMENT',
        instalment: instalments[instalments.length - 1].node,
      });
    }
  }, [instalments, setPaymentPlanLastInstalment]);

  if (
    isLoadingInstalments ||
    isLoadingBalance ||
    isLoadingProposedPaymentSchedule
  ) {
    return <LoadingState />;
  }

  if (isErrorInstalments || isErrorBalance || isErrorProposedPaymentSchedule) {
    return (
      <FixedScheduleError
        refetch={() => {
          void refetchInstalments();
        }}
      />
    );
  }

  const billingConfig = process.env.NEXT_PUBLIC_PAYMENT_PLAN_BILLING_CONFIG;

  const getPaymentSplitExplanationCopy = () => {
    if (notAtEndOfFinancialYear) {
      return `Your ${
        billingConfig === PAYMENT_PLAN_BILLING_CONFIG.FIRST_MORE
          ? 'first'
          : 'final'
      } payment may be ${
        billingConfig === PAYMENT_PLAN_BILLING_CONFIG.LAST_LESS
          ? 'lower'
          : 'higher'
      } to ensure payments are evenly split`;
    } else {
      return 'As you only have 1 instalment left, this will be your only payment.';
    }
  };

  const lastInstalment = instalments[instalments.length - 1].node;

  return (
    <>
      <PIITypography variant="body2">
        Your balance ({formatCurrencyWithCreditOrDebitIndicator(balance, false)}
        ) will now be split equally over{' '}
        <strong>{instalments.length} monthly instalments</strong> until{' '}
        {formatDateMonthYear(lastInstalment.date)}.{' '}
        {getPaymentSplitExplanationCopy()}
      </PIITypography>

      <PaymentForecastTable upcomingPayments={instalments} />
    </>
  );
};

const LoadingState = () => (
  <Stack direction="vertical" gap="md">
    <Stack gap="xs">
      <H4Skeleton />
    </Stack>

    {Array.from({ length: 2 }).map((_, i) => (
      <Stack key={i} fullWidth justifyContent="space-between">
        <Body1Skeleton />
        <Body1Skeleton />
      </Stack>
    ))}
  </Stack>
);

export default EstimatedInstalmentsTable;
