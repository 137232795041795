import type { GraphQLError } from 'graphql/error';

export type ValidationError = {
  inputPath: string[];
  message: string;
};

export interface KrakenError extends Partial<GraphQLError> {
  extensions: {
    errorClass: string;
    validationErrors: ValidationError[];
    errorCode?: string;
    errorType?: string;
    errorDescription?: string;
  };
  message: string;
  path: string[];
}

export interface KrakenErrorResponse {
  response: {
    data: {
      obtainKrakenToken: null;
    };
    errors: KrakenError[];
  };
}

export function isKrakenErrorResponse(
  apiResponse: unknown
): apiResponse is KrakenErrorResponse {
  return (
    typeof apiResponse === 'object' &&
    apiResponse !== null &&
    'response' in apiResponse &&
    typeof apiResponse.response === 'object' &&
    apiResponse.response !== null &&
    'errors' in apiResponse.response
  );
}
