import { useState } from 'react';
import {
  Stack,
  Typography,
  useCoralBreakpoints,
  Visibility,
} from '@krakentech/coral';

import Alert from '@/components/Alert';
import PIITypography from '@/components/PII/PIITypography';
import { DIRECT_DEBIT_INSTRUCTION_STATUS } from '@/consts/directDebit';
import { useHasActiveSocialAgreement } from '@/hooks/accounts/useHasActiveSocialAgreement';
import { useCurrentActiveDirectDebitInstruction } from '@/hooks/billsAndPayments/useCurrentActiveDirectDebitInstruction';
import {
  isFailedDirectDebitInstruction,
  isValidDirectDebitInstruction,
} from '@/utils/directDebit';
import { INTERNAL_PATHS } from '@/utils/urls';

import BoxWithGreyBorder from '../../../../BoxWithGreyBorder';
import MutedText from '../../../../MutedText';
import { Body1Skeleton } from '../../../../Skeletons';
import TryAgainError from '../../../../TryAgainError';
import SetUpDirectDebitCta from '../../SetUpDirectDebitCta';
import BankDetailsCard from '../BankDetailsCard';

const PaymentMethod = () => {
  const [hasRetriedQuery, setHasRetriedQuery] = useState(false);
  const { isMinMd } = useCoralBreakpoints();
  const {
    data: directDebitInstruction,
    isLoading,
    isError,
    refetch,
  } = useCurrentActiveDirectDebitInstruction();

  const { data: hasActiveSocialAgreement } = useHasActiveSocialAgreement();

  const hasValidDirectDebitInstruction = isValidDirectDebitInstruction(
    directDebitInstruction?.node
  );
  const hasFailedDirectDebitInstruction = isFailedDirectDebitInstruction(
    directDebitInstruction?.node
  );

  // Ensure the loading state is displayed if the refetch function is called from the TryAgainError component inside the BankDetailsCard
  if (isLoading) {
    return (
      <BoxWithGreyBorder>
        <Stack direction="vertical" gap="md">
          <Stack
            direction="vertical"
            gap="xs"
            alignItems="center"
            md={{
              direction: 'vertical',
              gap: 'xs',
              alignItems: 'flex-start',
            }}
          >
            <Body1Skeleton width={250} />
            <Body1Skeleton width={250} />
          </Stack>

          <BoxWithGreyBorder testId="bankDetailsCard" darkBackground>
            <Visibility md={{ display: 'none' }}>
              <Stack direction="vertical" gap="xs">
                <Body1Skeleton />
                <Body1Skeleton />
              </Stack>
            </Visibility>

            <Visibility display="none" md={{ display: 'block' }}>
              <Body1Skeleton />
            </Visibility>
          </BoxWithGreyBorder>
        </Stack>
      </BoxWithGreyBorder>
    );
  }

  if (isError) {
    return (
      <BoxWithGreyBorder>
        <Stack direction="vertical" gap="md">
          <Stack
            direction="vertical"
            gap="xs"
            alignItems="center"
            md={{
              direction: 'vertical',
              gap: 'xs',
              alignItems: 'flex-start',
            }}
          >
            <MutedText variant="h3" textAlign={isMinMd ? 'left' : 'center'}>
              Payment method:
            </MutedText>
          </Stack>

          <BoxWithGreyBorder darkBackground>
            <TryAgainError
              allowUserToRetryQuery={!hasRetriedQuery}
              retryQuery={() => {
                refetch();
                setHasRetriedQuery(true);
              }}
            >
              <Typography textAlign="center">
                Uh oh, looks like we can&apos;t display your payment method. Try
                again or come back later
              </Typography>
            </TryAgainError>
          </BoxWithGreyBorder>
        </Stack>
      </BoxWithGreyBorder>
    );
  }

  const hasDirectDebitInstruction = directDebitInstruction?.node?.id;

  const showBankDetailsCard = hasDirectDebitInstruction;

  const showSetUpDirectDebitCta =
    !hasDirectDebitInstruction && !hasActiveSocialAgreement;

  return (
    <BoxWithGreyBorder>
      <Stack direction="vertical" gap="md">
        <Stack
          direction="vertical"
          gap="xs"
          alignItems="center"
          md={{
            direction: 'vertical',
            gap: 'xs',
            alignItems: 'flex-start',
          }}
        >
          <MutedText variant="h3" textAlign={isMinMd ? 'left' : 'center'}>
            Payment method:
          </MutedText>
          <PIITypography textAlign={isMinMd ? 'left' : 'center'}>
            {hasValidDirectDebitInstruction
              ? 'Direct Debit'
              : 'Card, cash or cheque'}
          </PIITypography>
        </Stack>

        {!hasFailedDirectDebitInstruction && (
          <>
            {showSetUpDirectDebitCta && <SetUpDirectDebitCta />}
            {showBankDetailsCard && (
              <BankDetailsCard
                changeButtonHref={INTERNAL_PATHS.CHANGE_BANK_DETAILS.path}
              />
            )}
          </>
        )}

        {directDebitInstruction?.node?.status ===
          DIRECT_DEBIT_INSTRUCTION_STATUS.PROVISIONAL && (
          <Alert severity="info">
            We&apos;re processing the change to your bank details
          </Alert>
        )}
      </Stack>
    </BoxWithGreyBorder>
  );
};

export default PaymentMethod;
