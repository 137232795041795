import { KrakenError } from '@/types/errors';

// Normally we can use the useFeatureFlags hook instead and that is definitely preferred because it's cool and nice. This is only here for when we can't use a hook to get the feature flag, e.g. in util and lib functions like these
export const showAdobeAnalytics =
  process.env.NEXT_PUBLIC_FF_ADOBE_ANALYTICS === '1';

const getAnalyticsOptInCategory = (): string => {
  if (!window.adobe) {
    return '';
  }

  return window.adobe.OptInCategories['ANALYTICS'];
};

export const optInToAdobeAnalytics = (): void => {
  if (!window.adobe) {
    return;
  }

  const ADOBE_ANALYTICS_OPT_IN_CATEGORY = getAnalyticsOptInCategory();

  window.adobe.optIn.approve(ADOBE_ANALYTICS_OPT_IN_CATEGORY);
  window.adobe.optIn.complete();
};

export const optOutOfAdobeAnalytics = (): void => {
  if (!window.adobe) {
    return;
  }

  const ADOBE_ANALYTICS_OPT_IN_CATEGORY = getAnalyticsOptInCategory();

  window.adobe.optIn.deny(ADOBE_ANALYTICS_OPT_IN_CATEGORY);
  window.adobe.optIn.complete();
};

export const setAdobeAnalyticsConsent = (
  hasConsentedToAnalyticsStorage: boolean
): void => {
  if (showAdobeAnalytics) {
    if (hasConsentedToAnalyticsStorage) {
      optInToAdobeAnalytics();
    } else {
      optOutOfAdobeAnalytics();
    }
  }
};

export function initAdobeDigitalData(dynamicProperties: {
  isAuthenticated?: boolean;
  userId?: string;
  pathname?: string;
}): void {
  const { isAuthenticated, userId, pathname } = dynamicProperties || {};

  const uri = pathname || window.location.pathname;

  let categories: string[] = [];

  if (uri === '/') {
    categories.push('dashboard');
  }

  // Split path by "/", add non-empty segments to the array
  categories.push(...uri.split('/').filter((category) => category !== ''));

  // Filter out strings with "[" and "]" and exclude "accounts"
  categories = categories.filter(
    (category) =>
      !category.includes('[') &&
      !category.includes(']') &&
      category !== 'accounts'
  );

  const pageName = `${categories
    .map((category) => category.replace(/-/g, ' '))
    .reverse()
    .join(':')}:${process.env.NEXT_PUBLIC_COMPANY_NAME?.toLowerCase()}`;

  window.digitalData = {
    page: {
      name: pageName,
      uri,
      primary_category: categories[0] || '',
      sub_category1: categories[1] || '',
      sub_category2: categories[2] || '',
      sub_category3: '',
    },
    user: {
      logged_in_status: isAuthenticated ? 'logged in' : 'not logged in',
      user_id: userId || '',
    },
  };
}

type AdobeErrorEvent = Pick<
  Required<KrakenError>['extensions'],
  'errorCode' | 'errorType' | 'errorDescription'
>;

export function trackAdobeErrorEvent({
  errorType = '',
  errorCode = '',
  errorDescription = '',
}: Partial<Record<keyof AdobeErrorEvent, string>>) {
  if (window._satellite) {
    window.digitalData = {
      ...window.digitalData,
      error: {
        error_type: errorType,
        error_code: errorCode,
        error_description: errorDescription,
      },
    };
    window._satellite.track('transaction_error');
  }
}
