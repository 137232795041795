import { Grid } from '@krakentech/coral';
import { IconDroplet } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { INTERNAL_PATHS } from '@/utils/urls';

type SubmitNewReadingCardProps = {
  meterId: string;
  isLoading: boolean;
  isError: boolean;
};

const SubmitNewReadingCard = ({
  meterId,
  isLoading,
  isError,
}: SubmitNewReadingCardProps) => {
  return (
    <Grid.Item>
      <ActionCard
        href={INTERNAL_PATHS.SUBMIT_METER_READING.createPath({ meterId })}
        title="Enter a reading"
        titleComponent="p"
        icon={<IconDroplet size={32} />}
        isLoading={isLoading}
        isError={isError}
      />
    </Grid.Item>
  );
};

export default SubmitNewReadingCard;
