import NextImage from 'next/image';

import { STAccessibilityIcon } from '@/public/images';

import { StyledButton } from './index.styled';

const ReciteMe = () => (
  <StyledButton id="enableRecite">
    <NextImage
      src={STAccessibilityIcon}
      alt={'Accessibility'}
      width="50"
      height="50"
    />
  </StyledButton>
);

export default ReciteMe;
