// This is a query used on the SetUpDDLater component. Basically this is for unmetered ST customers who want to pay in full/two instalments, but aren't in debit at the time of setting up their DDI. The query is used to determine the first possible collection date for the customer. They can't use the PayInFullInstalments query right now, as the paymentAmounts query errors for these customers. It would be nice to not have this as a separate query, if possible, because it just makes the whole thing messier.
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { DIRECT_DEBIT_SCHEDULE_CHOICES } from '@/consts/paymentFrequency';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { firstPossibleCollectionDateSchema } from '../validations';

import FirstPossibleCollectionDate from './FirstPossibleCollectionDate.graphql';

export const useFirstPossibleCollectionDate = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['firstPossibleCollectionDate'],
    query: FirstPossibleCollectionDate,
    select: (data: any) =>
      data.proposedDirectDebitPaymentSchedule.firstPossibleCollectionDate,
    variables: {
      accountNumber,
      scheduleType: DIRECT_DEBIT_SCHEDULE_CHOICES.BIANNUAL_INSTALMENTS,
    },
    validateFn(response) {
      return firstPossibleCollectionDateSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
  });
};
