import styled from 'styled-components';

export const StyledButton = styled.button`
  position: fixed;
  z-index: 31;
  top: 50%;
  right: 0;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
`;
