import { ReactNode } from 'react';
import { Button, Stack, Typography, useCoralTheme } from '@krakentech/coral';
import { IconCircleCheckmarkFilled } from '@krakentech/icons';

import FormContainer from '../forms/FormContainer';
import {
  Body1Skeleton,
  CircularSkeleton,
  FullWidthButtonSkeleton,
  H1Skeleton,
} from '../Skeletons';

const SuccessPage = ({
  title,
  linkHref,
  linkTitle,
  Explanation,
  isLoading = false,
  displayLink = true,
  onClick,
}: {
  title: string | ReactNode;
  linkHref?: string;
  linkTitle: string;
  Explanation?: ReactNode;
  isLoading?: boolean;
  displayLink?: boolean;
  onClick?: () => void;
}) => {
  const {
    color: {
      action: { success },
    },
  } = useCoralTheme();

  return (
    <FormContainer>
      {isLoading ? (
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="md" alignItems="center">
            <CircularSkeleton width="6.3rem" height="6.3rem" />

            <H1Skeleton />

            <Body1Skeleton width={300} />
            <Body1Skeleton width={300} />

            <FullWidthButtonSkeleton />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="vertical" gap="lg">
          <Stack direction="vertical" gap="md" alignItems="center">
            <IconCircleCheckmarkFilled color={success} size={63} />

            <Stack direction="vertical" gap="sm" alignItems="center">
              <Typography variant="h1" textAlign="center">
                {title}
              </Typography>

              {Explanation}
            </Stack>
          </Stack>
          {displayLink && (
            <Button href={linkHref} size="medium" fullWidth onClick={onClick}>
              {linkTitle}
            </Button>
          )}
        </Stack>
      )}
    </FormContainer>
  );
};

export default SuccessPage;
