import { array, boolean, mixed, number, object, string, date } from 'yup';

import { BillTypeEnum } from '@/types/generated/graphql/schema';

export const billsResponseSchema = object().shape({
  account: object()
    .shape({
      bills: object()
        .shape({
          edges: array(
            object({
              cursor: string().required(),
              node: object()
                .shape({
                  id: string().required(),
                  billType: mixed<BillTypeEnum>()
                    .oneOf(Object.values(BillTypeEnum))
                    .required(),
                  fromDate: string().required(),
                  toDate: string().required(),
                  temporaryUrl: string().required(),
                  issuedDate: string().required(),
                  totalCharges: object().shape({
                    grossTotal: number().optional(), // Not every bill will have a gross total, e.g. if the bill's billType is PRE_KRAKEN. So this needs to be "optional" to prevent the page from crashing
                  }),
                })
                .optional(),
            }).required()
          ).required(),
          edgeCount: number().required(),
          pageInfo: object()
            .shape({
              endCursor: string().nullable(),
              startCursor: string().nullable(),
              hasNextPage: boolean().required(),
              hasPreviousPage: boolean().required(),
            })
            .required(),
          totalCount: number().required(),
        })
        .required(),
    })
    .required(),
});

export const latestBillResponseSchema = object().shape({
  account: object().shape({
    bills: object()
      .shape({
        edges: array(
          object({
            node: object().shape({
              id: string(),
              billType: string(),
            }),
          })
        ),
      })
      .required(),
  }),
});

export const singleBillResponseSchema = object().shape({
  account: object().shape({
    bill: object().shape({
      temporaryUrl: string().required(),
    }),
  }),
});

export const ledgersSuccessSchema = object({
  account: object({
    ledgers: array(
      object({
        id: string().required(),
        ledgerType: string().required(),
      })
    ),
  }),
});

export const paymentsSuccessSchema = object().shape({
  account: object().shape({
    transactions: object()
      .shape({
        edges: array(
          object({
            cursor: string().required(),
            node: object().shape({
              id: string().required(),
              amount: number().required(),
              balanceCarriedForward: number().required(),
              postedDate: date().required(),
              __typename: string().required(),
              title: string().required(),
              consumption: object()
                .shape({
                  startDate: date().nullable(),
                  endDate: date().nullable(),
                })
                .nullable(),
            }),
          }).required()
        ).required(),
        edgeCount: number().required(),
        pageInfo: object()
          .shape({
            endCursor: string().nullable(),
            startCursor: string().nullable(),
            hasNextPage: boolean().required(),
            hasPreviousPage: boolean().required(),
          })
          .required(),
        totalCount: number().required(),
      })
      .required(),
  }),
});

export const initiateStandalonePaymentSchema = object({
  initiateStandalonePayment: object({
    payment: object({
      retrievalToken: string().required(),
      secretToken: string().required(),
    }),
  }),
});

// Manage payments and Direct Debit
const paymentSchedule = {
  id: string().required(),
  paymentDay: number().nullable(), // This can be null now, yay
  paymentAmount: number().required(), // This is always 0 if the user is on a variable payment schedule
  paymentFrequency: string().required(),
  paymentFrequencyMultiplier: string().required(),
  isVariablePaymentAmount: boolean().required(),
  scheduleType: string().nullable(),
};

export const currentActivePaymentScheduleSchema = object({
  account: object({
    paymentSchedules: object({
      edges: array(
        object({
          node: object({
            ...paymentSchedule,
            validTo: string().required().nullable(),
          }),
        })
      ),
    }),
  }),
});

export const upcomingPaymentScheduleSchema = object({
  account: object({
    paymentSchedules: object({
      edges: array(
        object({
          node: object({
            ...paymentSchedule,
            validFrom: date().required(),
          }),
        })
      ),
    }),
  }),
});

export const currentActiveDirectDebitInstructionSchema = object({
  account: object({
    directDebitInstructions: object({
      edges: array(
        object({
          node: object({
            id: string(),
            sortCode: string(),
            accountHolder: string(),
            maskedAccountIdentifier: string(),
          }),
        })
      ),
    }),
  }),
});

export const paymentForecastSchema = object({
  account: object({
    paginatedPaymentForecast: object({
      edges: array(
        object({
          node: object({
            date: date().required(),
            amount: number().required(),
          }),
        })
      ),
    }),
  }),
});

export const proposedDirectDebitPaymentScheduleSchema = object({
  proposedDirectDebitPaymentSchedule: object({
    firstPossibleCollectionDate: date().required(),
    amount: number().nullable(),
  }),
});

export const payInFullInstalmentsSchema = object({
  proposedDirectDebitPaymentSchedule: object({
    firstPossibleCollectionDate: date().required(),
    paymentAmounts: object({
      edges: array(
        object({
          node: object({
            paymentNumber: number(),
            date: date(),
            amount: number(),
          }),
        })
      ),
    }),
  }),
});

export const paymentPlanInstalmentSchema = object({
  proposedDirectDebitPaymentSchedule: object({
    paymentAmounts: object({
      edges: array(
        object({
          node: object({
            paymentNumber: number(),
            date: date(),
            amount: number(),
          }),
        })
      ),
    }),
  }),
});

export const firstPossibleCollectionDateSchema = object({
  proposedDirectDebitPaymentSchedule: object({
    firstPossibleCollectionDate: date().required(),
  }),
});

export const createDirectDebitInstructionAndScheduleSchema = object({
  createDirectDebitInstructionAndSchedule: object({
    paymentSchedule: object({
      id: string().required(),
    }),
    paymentInstruction: object({
      id: string().required(),
    }),
  }),
});

export const changeBankDetailsSchema = object({
  setUpDirectDebitInstruction: object({
    paymentInstruction: object({
      id: string().required(),
    }),
  }),
});

export const changePaymentScheduleSchema = object({
  createDirectDebitPaymentSchedule: object({
    paymentSchedule: object({
      id: string().required(),
    }),
  }),
});

// Payment schedule stuff on overview page
export const hasCurrentDirectDebitInstructionSchema = object({
  account: object({
    directDebitInstructions: object({
      edges: array(
        object({
          node: object({
            id: string(),
            status: string(),
          }),
        })
      ),
    }),
  }),
});

export const requestPrintedBillSchema = object({
  requestPrintedBill: object({
    success: boolean().required(),
  }),
});
