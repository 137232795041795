import {
  Button,
  Stack,
  Loader,
  toast,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { IconArrowRightFromBracket } from '@krakentech/icons';
import { useIsClient } from '@krakentech/utils';

import { useForceReauthentication } from '@/hooks/auth/useForceReauthentication';

const LogoutButton = () => {
  const { isMaxMd } = useCoralBreakpoints();
  const isClient = useIsClient();

  const isMaxMdBreakpoint = isClient && isMaxMd;

  const displayError = () => {
    toast('There was a problem logging you out, please try again', {
      type: 'error',
    });
  };
  const { mutate, isLoading } = useForceReauthentication({ displayError });

  const logout = () => {
    mutate({
      input: {
        includeThirdParties: true,
      },
    });
  };

  return (
    <Button
      color="tertiary"
      size={isMaxMdBreakpoint ? 'medium' : 'small'}
      title="Logout"
      onClick={logout}
      disabled={isLoading}
    >
      {isMaxMdBreakpoint ? (
        <>
          {isLoading ? (
            <Loader size={16} color="text" />
          ) : (
            <IconArrowRightFromBracket size={18} />
          )}
        </>
      ) : (
        <Stack gap="sm" alignItems="center">
          {isLoading ? (
            <Loader size={16} color="text" />
          ) : (
            <IconArrowRightFromBracket size={18} />
          )}
          <span>Logout</span>
        </Stack>
      )}
    </Button>
  );
};

export default LogoutButton;
