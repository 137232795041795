import { useEffect } from 'react';
import { Typography } from '@krakentech/coral';

import { Body1Skeleton } from '@/components/Skeletons';
import { useDirectDebitContext } from '@/context';
import { useFirstPossibleCollectionDate } from '@/hooks/billsAndPayments/useFirstPossibleCollectionDate';

import FixedScheduleError from '../FixedScheduleError';

const SetUpDDLater = () => {
  const {
    data: firstPossibleCollectionDate,
    isLoading,
    error,
    refetch,
  } = useFirstPossibleCollectionDate();
  const { setFirstPossibleVariableSchedulePaymentDate } =
    useDirectDebitContext();

  useEffect(() => {
    if (firstPossibleCollectionDate) {
      setFirstPossibleVariableSchedulePaymentDate(firstPossibleCollectionDate);
    }
  }, [
    firstPossibleCollectionDate,
    setFirstPossibleVariableSchedulePaymentDate,
  ]);

  if (isLoading) {
    return <Body1Skeleton />;
  }

  if (error) {
    return <FixedScheduleError refetch={refetch} />;
  }

  return (
    <Typography>
      As you currently don&apos;t owe anything, we will set up your Direct Debit
      shortly after your next bill.
    </Typography>
  );
};

export default SetUpDDLater;
