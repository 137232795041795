import { useRouter } from 'next/router';
import {
  Stack,
  Tooltip,
  Typography,
  useCoralBreakpoints,
  Button,
} from '@krakentech/coral';
import { IconArrowRotate } from '@krakentech/icons';
import { useIsClient } from '@krakentech/utils';

import PIIStack from '@/components/PII/PIIStack';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useFullNameAndSupplyAddress } from '@/hooks/accounts/useFullNameAndSupplyAddress';
import { useIsPortfolioAccount } from '@/hooks/accounts/useIsPortfolioAccount';
import { useIsOnMeasuredTariff } from '@/hooks/usage/useIsOnMeasuredTariff';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

import { Body1Skeleton, H1Skeleton } from '../../Skeletons';

import { StyledTooltipToggle } from './index.styled';

const DashboardGreeting = () => {
  const router = useRouter();
  const { isMaxMd, isMinMd } = useCoralBreakpoints();
  const isClient = useIsClient();
  const isMinMdBreakpoint = isMinMd && isClient;
  const { isAuthenticated } = useAuth();
  const { isPortfolioAccount } = useIsPortfolioAccount(isAuthenticated);
  const portfolioEnabled = useFeatureFlag(FeatureNames.Portfolio);

  const { accountNumber } = useAccountNumberContext();

  const {
    data: fullNameAndSupplyAddress,
    isError: fullNameSupplyAddressError,
  } = useFullNameAndSupplyAddress();
  const { data: isMeasured, isError: isMeasuredError } =
    useIsOnMeasuredTariff();

  if (fullNameAndSupplyAddress) {
    const {
      viewer: { title, fullName },
      account: { properties },
    } = fullNameAndSupplyAddress;

    const hasMovedOut = !properties.length;
    const showSwitchAccount =
      portfolioEnabled && isPortfolioAccount && isMinMdBreakpoint;
    const shouldShowTooltips = !showSwitchAccount;

    return (
      <PIIStack direction="vertical" gap="xxs" md={{ gap: 'xs' }}>
        <Typography variant="h1">
          Hello {title} {fullName}
        </Typography>

        <Stack
          gap="xxs"
          direction="vertical"
          alignItems="flex-start"
          md={{
            direction: 'horizontal',
            gap: 'sm',
            flexWrap: 'wrap',
          }}
        >
          {/* TODO: handle those with multiple addresses (not necessary for this cohort) */}
          {properties[0]?.address && (
            <>
              <Typography>{properties[0]?.address}</Typography>
              {!isMaxMd && <Typography>|</Typography>}
            </>
          )}

          <Stack gap="xs" md={{ gap: 'sm' }}>
            {shouldShowTooltips ? (
              <Tooltip title="Your account number" theme="dark" zIndex={1}>
                <StyledTooltipToggle>
                  <Typography>{accountNumber}</Typography>
                </StyledTooltipToggle>
              </Tooltip>
            ) : (
              <Typography>{accountNumber}</Typography>
            )}
            {!hasMovedOut && typeof isMeasured === 'boolean' && (
              <>
                <Typography>|</Typography>
                {shouldShowTooltips ? (
                  <Tooltip
                    theme="dark"
                    title={
                      isMeasured
                        ? 'You have a metered agreement, so your bill is calculated based on the water you use'
                        : "You have an unmetered agreement, so your bill is calculated using your property's value or an estimated usage"
                    }
                    zIndex={1}
                  >
                    <Typography>
                      <StyledTooltipToggle>
                        {isMeasured ? 'Metered' : 'Unmetered'}
                      </StyledTooltipToggle>
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography>
                    {isMeasured ? 'Metered' : 'Unmetered'}
                  </Typography>
                )}
              </>
            )}
            {showSwitchAccount && (
              <>
                <Typography>|</Typography>
                <Button
                  variant="link"
                  onClick={() =>
                    router.push(INTERNAL_PATHS.PORTFOLIO_OVERVIEW.path)
                  }
                  startIcon={<IconArrowRotate size={16} />}
                >
                  <Typography textWrap="nowrap">Switch Account</Typography>
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </PIIStack>
    );
  }

  if (fullNameSupplyAddressError || isMeasuredError) {
    return null;
  }

  return (
    <Stack direction="vertical" gap="sm">
      <H1Skeleton />
      <Body1Skeleton width={isMaxMd ? undefined : 750} />
    </Stack>
  );
};

export default DashboardGreeting;
