import { useKrakenMutation } from '@/components/GraphQLClient';
import { COMMS_DELIVERY_PREFERENCES } from '@/consts/commsDeliveryPreferences';
import { useCommsPreferencesContext } from '@/context';
import { useCommsAndMarketingPreferences } from '@/hooks/accounts/useCommsAndMarketingPreferences';
import { updateCommsDeliveryPreferenceSchema } from '@/hooks/accounts/validations';

import UpdateCommsDeliveryPreference from './UpdateCommsDeliveryPreference.graphql';

export const useUpdateCommsDeliveryPreference = () => {
  const { refetch } = useCommsAndMarketingPreferences();
  const { setMutationLoading, setMutationError, setMutationSuccess } =
    useCommsPreferencesContext();

  return useKrakenMutation({
    mutation: (_: any) => UpdateCommsDeliveryPreference,
    validateFn(response) {
      return updateCommsDeliveryPreferenceSchema.validate(response);
    },
    onSuccess: ({
      updateCommsDeliveryPreference: {
        account: { commsDeliveryPreference },
      },
    }) => {
      // Refetch the query to get this data so the mobile number is displayed on the page
      refetch();

      // Set the mutation loading state to false
      setMutationLoading(false);

      // Display mutation success alert
      setMutationSuccess(
        `You have updated your contact preference to be by ${
          commsDeliveryPreference === COMMS_DELIVERY_PREFERENCES.EMAIL
            ? 'email'
            : 'post'
        }`
      );
    },
    onError: () => {
      // Set the mutation loading state to false
      setMutationLoading(false);

      // Display the mutation error
      setMutationError();
    },
  });
};
