import { useKrakenMutation } from '@/components/GraphQLClient';
import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';
import { MOBILE_FORM_DIALOG_TOGGLE_ORIGIN } from '@/consts/mobileFormDialogToggleOrigin';
import { useCommsPreferencesContext } from '@/context';
import { useCommsAndMarketingPreferences } from '@/hooks/accounts/useCommsAndMarketingPreferences';
import { useUpdateCommsPreferences } from '@/hooks/accounts/useUpdateCommsPreferences';
import { updateUserMobileNumberSchema } from '@/hooks/accounts/validations';
import { MutationUpdateUserArgs } from '@/types/generated/graphql/schema';

import { UseUpdateMobileNumberArgs } from './index.types';
import UpdateUserMobileNumber from './UpdateUserMobileNumber.graphql';

export const useUpdateUserMobileNumber = ({
  closeDialog,
  toggleSmsSwitchOn,
}: UseUpdateMobileNumberArgs) => {
  const {
    setMutationLoading,
    mobileFormDialogToggleOrigin,
    setMutationSuccess,
  } = useCommsPreferencesContext();
  const { mutate: mutateUpdateCommsPreferences } = useUpdateCommsPreferences();
  const { refetch } = useCommsAndMarketingPreferences();

  return useKrakenMutation({
    mutation: (_: MutationUpdateUserArgs) => UpdateUserMobileNumber,
    validateFn(response) {
      return updateUserMobileNumberSchema.validate(response);
    },
    onSuccess: async () => {
      const handleMobileNumberMutationSuccess = () => {
        // Disable all the inputs
        setMutationLoading(true);

        // Close the dialog
        closeDialog();

        // Display mutation success alert
        setMutationSuccess('Your mobile number has been updated');
      };

      if (
        mobileFormDialogToggleOrigin === MOBILE_FORM_DIALOG_TOGGLE_ORIGIN.SWITCH
      ) {
        handleMobileNumberMutationSuccess();

        // Toggle the switch to true
        toggleSmsSwitchOn();

        // Run update comms preferences mutation
        mutateUpdateCommsPreferences({
          input: {
            [MARKETING_PREFERENCE_OPTIONS.SMS]: true,
          },
        });
      } else {
        handleMobileNumberMutationSuccess();

        // Refetch the query to get this data so the mobile number is displayed on the page
        refetch();

        // Turn off the mutation loading state
        setMutationLoading(false);
      }
    },
  });
};
