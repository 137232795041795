import { isFuture } from 'date-fns';

import { Alert } from '@/components';
import PaymentScheduleCopy from '@/components/billingAndPayment/UpcomingPaymentSchedule/PaymentScheduleCopy';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { usePaymentForecast } from '@/hooks/billsAndPayments/usePaymentForecast';
import { useUpcomingPaymentSchedule } from '@/hooks/billsAndPayments/useUpcomingPaymentSchedule';

import { getActiveOnDate, getFinalPaymentDetails } from './helpers';

const UpcomingPaymentSchedule = () => {
  const {
    isLoading: isLoadingCurrentPaymentSchedule,
    error: isErrorCurrentPaymentSchedule,
    data: currentPaymentSchedule,
  } = useCurrentActivePaymentSchedule();

  const validToDate = currentPaymentSchedule?.node?.validTo;
  const activeOnDate = getActiveOnDate(validToDate);

  const {
    isLoading: isLoadingUpcomingPaymentSchedule,
    error: isErrorUpcomingPaymentSchedule,
    data: upcomingPaymentSchedule,
  } = useUpcomingPaymentSchedule({
    activeOnDate,
  });

  const {
    isLoading: upcomingPaymentsLoading,
    isError: upcomingPaymentsError,
    data: upcomingPayments,
  } = usePaymentForecast({
    first: 12,
  });

  if (
    isLoadingCurrentPaymentSchedule ||
    isLoadingUpcomingPaymentSchedule ||
    upcomingPaymentsLoading
  ) {
    return null;
  }

  if (
    isErrorCurrentPaymentSchedule ||
    isErrorUpcomingPaymentSchedule ||
    upcomingPaymentsError
  ) {
    return null;
  }

  // Don't show anything if the user doesn't have an upcoming payment schedule
  if (!upcomingPaymentSchedule?.node) {
    return null;
  }

  // Don't show anything if the "upcoming payment schedule" has already started. This can happen for customers whose payment schedule has an end date, but there's no payment schedule afterwards
  const { validFrom } = upcomingPaymentSchedule.node;

  if (!isFuture(validFrom)) {
    return null;
  }

  const { isFinalPayment, finalPaymentDay, finalPaymentMonth } =
    getFinalPaymentDetails(upcomingPayments);

  const paymentScheduleDetails = {
    ...upcomingPaymentSchedule.node,
    isFinalPayment,
    finalPaymentDay,
    finalPaymentMonth,
    upcomingPayments,
  };

  return (
    <Alert severity="info">
      <PaymentScheduleCopy paymentScheduleDetails={paymentScheduleDetails} />
    </Alert>
  );
};

export default UpcomingPaymentSchedule;
