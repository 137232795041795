import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';

import { FeatureNames } from '@/types/features';

import { EnvFlagsType, QueryParams } from './index.types';

// a map of flag names to their values
const FLAG_NAME_TO_ENV_VALUE: EnvFlagsType = {
  [FeatureNames.OnlinePayment]:
    (process.env.NEXT_PUBLIC_FF_ONLINE_PAYMENT as string) || undefined,
  [FeatureNames.DirectDebit]:
    (process.env.NEXT_PUBLIC_FF_DIRECT_DEBIT as string) || undefined,
  [FeatureNames.ChangeBankDetails]:
    (process.env.NEXT_PUBLIC_FF_CHANGE_BANK_DETAILS as string) || undefined,
  [FeatureNames.ChangePaymentSchedule]:
    (process.env.NEXT_PUBLIC_FF_CHANGE_PAYMENT_SCHEDULE as string) || undefined,
  [FeatureNames.PSRRenewal]:
    (process.env.NEXT_PUBLIC_FF_PSR_RENEWAL as string) || undefined,
  [FeatureNames.RequestPrintedBill]:
    (process.env.NEXT_PUBLIC_FF_REQUEST_PRINTED_BILL as string) || undefined,
  [FeatureNames.HomeMove]:
    (process.env.NEXT_PUBLIC_FF_HOME_MOVES as string) || undefined,
  [FeatureNames.RegisterForOnlineAccount]:
    (process.env.NEXT_PUBLIC_FF_REGISTER_FOR_ONLINE_ACCOUNT as string) ||
    undefined,
  [FeatureNames.ForgottenEmail]:
    (process.env.NEXT_PUBLIC_FF_FORGOTTEN_EMAIL as string) || undefined,
  [FeatureNames.CreateNewAccount]:
    (process.env.NEXT_PUBLIC_FF_CREATE_NEW_ACCOUNT as string) || undefined,
  [FeatureNames.AdobeAnalytics]:
    (process.env.NEXT_PUBLIC_FF_ADOBE_ANALYTICS as string) || undefined,
  [FeatureNames.GoogleAnalytics]:
    (process.env.NEXT_PUBLIC_FF_GOOGLE_ANALYTICS as string) || undefined,
  [FeatureNames.NectarPhaseOne]:
    (process.env.NEXT_PUBLIC_FF_NECTAR_PHASE_ONE as string) || undefined,
  [FeatureNames.ApplyForMeter]:
    (process.env.NEXT_PUBLIC_FF_APPLY_FOR_METER as string) || undefined,
  [FeatureNames.MoversDetails]:
    (process.env.NEXT_PUBLIC_FF_MOVERS_DETAILS as string) || undefined,
  [FeatureNames.NewEditDirectDebitUnmeasuredUI]:
    (process.env.NEXT_PUBLIC_FF_NEW_EDIT_DD_UNMEASURED_UI as string) ||
    undefined,
  [FeatureNames.ChargeDetails]:
    (process.env.NEXT_PUBLIC_FF_CHARGE_DETAILS as string) || undefined,
  [FeatureNames.Portfolio]:
    (process.env.NEXT_PUBLIC_FF_PORTFOLIO as string) || undefined,
  [FeatureNames.PayInFull]:
    (process.env.NEXT_PUBLIC_FF_PAY_IN_FULL as string) || undefined,
  [FeatureNames.ReciteMe]:
    (process.env.NEXT_PUBLIC_FF_RECITE_ME as string) || undefined,
};

export const useFeatureFlag = (flagName: string): boolean => {
  const [isFeatureShown, setIsFeatureShown] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const queryParams = (router.query as QueryParams) || {};
    const { FF_ALL } = queryParams;
    if (router.isReady) {
      const featureFlagValue =
        FF_ALL === '1' || !isFeatureHidden({ flagName, queryParams });
      setIsFeatureShown(featureFlagValue);
      datadogRum.addFeatureFlagEvaluation(flagName, featureFlagValue);
    }
  }, [flagName, router.isReady, router.query]);

  return isFeatureShown;
};

export const useSyncFeatureFlag = (flagName: FeatureNames): boolean => {
  return FLAG_NAME_TO_ENV_VALUE[flagName] === '1';
};

export const getExplicitFeatureFlag = ({
  flagName,
  queryParams,
}: {
  flagName: string;
  queryParams: QueryParams;
}): string => {
  const explicitFlagName = flagName.replace('NEXT_PUBLIC_', '');
  return queryParams[explicitFlagName] || queryParams[flagName];
};

export const isFeatureHidden = ({
  flagName,
  queryParams = {},
  environmentFlags = FLAG_NAME_TO_ENV_VALUE,
}: {
  flagName: string;
  queryParams?: QueryParams;
  environmentFlags?: EnvFlagsType;
}): boolean => {
  const explicitFeatureFlag = getExplicitFeatureFlag({
    flagName,
    queryParams,
  });

  // User manually set feature flag
  if (explicitFeatureFlag === '1') {
    return false;
  }

  // User manually disabled feature flag
  if (explicitFeatureFlag === '0') {
    return true;
  }

  if (typeof window !== 'undefined') {
    /* checked we're not on the server */
    if (window.localStorage.getItem(flagName) === '1') {
      return false;
    }
  }

  if (flagName in environmentFlags) {
    // if we haven't set it in the environment, or we've set it to 0, hide it
    const environmentValue = environmentFlags[flagName as keyof EnvFlagsType];
    return environmentValue === '0' || environmentValue === undefined;
  }
  // if there has been nothing set for the flag, we should hide it
  return true;
};
