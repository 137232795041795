import { useRouter } from 'next/router';
import {
  Stack,
  useCoralBreakpoints,
  Link,
  Typography,
} from '@krakentech/coral';
import { IconArrowRight } from '@krakentech/icons';
import { useIsClient } from '@krakentech/utils';
import styled, { css } from 'styled-components';

import PIITypography from '@/components/PII/PIITypography';
import { useAuth } from '@/components/Providers/Auth';
import { useFullNameAndSupplyAddress } from '@/hooks/accounts/useFullNameAndSupplyAddress';
import { useIsPortfolioAccount } from '@/hooks/accounts/useIsPortfolioAccount';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { INTERNAL_PATHS } from '@/utils/urls';

type BreadcrumbHeaderProps = {
  headerHeight: number;
};

const BreadcrumbHeader = ({ headerHeight }: BreadcrumbHeaderProps) => {
  const { isMaxMd } = useCoralBreakpoints();
  const { isAuthenticated } = useAuth();
  const { isPortfolioAccount } = useIsPortfolioAccount(isAuthenticated);
  const portfolioEnabled = useFeatureFlag(FeatureNames.Portfolio);
  const router = useRouter();
  const { data: fullNameAndSupplyAddress } = useFullNameAndSupplyAddress();
  const isClient = useIsClient();

  const isLoginPage = router.pathname === INTERNAL_PATHS.LOGIN.path;
  const isPortfolioPage =
    router.pathname === INTERNAL_PATHS.PORTFOLIO_OVERVIEW.path;

  const shouldShow =
    isClient &&
    isMaxMd &&
    portfolioEnabled &&
    isPortfolioAccount &&
    !isLoginPage &&
    !isPortfolioPage;

  if (!shouldShow || !fullNameAndSupplyAddress) {
    return null;
  }

  const address = fullNameAndSupplyAddress.account.properties[0]?.address;

  return (
    <StyledHeader top={headerHeight}>
      <Stack direction="horizontal" alignItems="center" gap="xsSm">
        <Link href={INTERNAL_PATHS.PORTFOLIO_OVERVIEW.path}>
          <Typography variant="h6">Accounts</Typography>
        </Link>
        <IconArrowRight size={18} />
        <PIITypography color="inherit" variant="body2">
          {address}
        </PIITypography>
      </Stack>
    </StyledHeader>
  );
};

export default BreadcrumbHeader;

const StyledHeader = styled.div<{ top: number }>`
  ${({ theme, top }) => css`
    display: flex;
    align-items: center;
    position: sticky;
    top: ${top}px; // Position below main header
    background-color: ${theme.color.secondary.main};
    min-height: 2.5rem;
    padding: ${theme.spacing.smMd} ${theme.spacing.md};
    z-index: 31;
    color: white;

    a {
      color: white;
    }

    & svg path {
      fill: white;
    }
  `}
`;
