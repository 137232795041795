import { isPwl } from '@/utils/environment';

export const NO_FINAL_READ = 'NO_FINAL_READ';

export enum MOVE_HOME {
  MOVE_OUT = 'Move out',
  FINAL_READ = 'Final read',
  NEW_HOME = 'New home',
  NEXT_RESIDENT = 'Next resident',
  REVIEW = 'Review',
  SUCCESS = 'Success',
}

export const MOVE_DATE_LABEL = 'Move date (dd/mm/yyyy)';

export const getMaxDaysInFutureForHomeMove = () => (isPwl() ? 14 : 28);

export const MOVE_DATE_MESSAGE = `Enter the date your tenancy starts or your property sale is completed - this can be no more than ${getMaxDaysInFutureForHomeMove()} days in the future`;
