import { ChangeEventHandler, MouseEventHandler, useCallback } from 'react';
import { Typography, Stack } from '@krakentech/coral';
import { FieldProps } from 'formik';

import { piiDataAttributes } from '@/components/PII/constants';

import {
  StyledRadioCardInput,
  StyledRadioCard,
  StyledRadioCardContent,
  StyledRadioPresentation,
} from './index.styled';
import { RadioCardProps } from './index.types';

type ExtendedRadioCardProps = RadioCardProps & FieldProps;

const RadioCard: React.FC<ExtendedRadioCardProps> = ({
  value,
  label,
  explanation,
  form,
  field,
  onChange: onChangeCallback,
  onClick: onClickCallback,
  disabled,
  dataTestId,
  maskPII,
}) => {
  const isChecked = field.value === value;
  const uniqueId = `${field.name}${value}`;

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChangeCallback?.(e);
      form.setFieldValue(field.name, value, true);
    },
    [field, form, value, onChangeCallback]
  );

  const onClick: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onClickCallback?.(e);
    },
    [onClickCallback]
  );

  return (
    <StyledRadioCard
      isChecked={isChecked}
      disabled={disabled}
      data-testid={dataTestId}
      {...(maskPII ? piiDataAttributes : {})}
    >
      <StyledRadioCardInput
        type="radio"
        name={field.name}
        id={uniqueId}
        value={value}
        onChange={onChange}
        onClick={onClick}
        checked={isChecked}
        disabled={disabled}
        {...(maskPII ? piiDataAttributes : {})}
      />
      <StyledRadioCardContent>
        <Stack alignItems="normal">
          <StyledRadioPresentation isChecked={isChecked} disabled={disabled} />

          <Stack direction="vertical" gap="xxs">
            <Typography>
              <label htmlFor={uniqueId} {...(maskPII ? piiDataAttributes : {})}>
                {label}
              </label>
            </Typography>
            {explanation && (
              <Typography
                variant="body2"
                {...(maskPII ? piiDataAttributes : {})}
              >
                {explanation}
              </Typography>
            )}
          </Stack>
        </Stack>
      </StyledRadioCardContent>
    </StyledRadioCard>
  );
};

export default RadioCard;
