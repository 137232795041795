import { Button, Link, useCoralTheme, Visibility } from '@krakentech/coral';
import { IconMagnifyingGlass } from '@krakentech/icons';
import { useFormikContext } from 'formik';

import { FormFieldWithButton } from '@/components';
import { PostcodeInput } from '@/components/AddressFinder/PostcodeInput';

export type PostcodeSearchProps = {
  onChange: (postcode: string) => void;
  onSearch: () => void;
  disabled?: boolean;
  loading: boolean;
};

export function PostcodeSearch({
  onChange,
  onSearch,
  disabled,
  loading,
}: PostcodeSearchProps) {
  const {
    errors: { postcode: postcodeError },
    values: { postcode: postcodeValue },
  } = useFormikContext<{ postcode: string }>();

  return (
    <FormFieldWithButton
      input={<PostcodeInput onPostcodeChanged={onChange} />}
      button={
        <FindAddressButton
          onTrigger={onSearch}
          disabled={
            disabled ||
            loading ||
            postcodeValue?.trim() === '' ||
            !!postcodeError
          }
          loading={loading}
        />
      }
      mutedText={
        <>
          Please only enter a UK postcode. If you can&apos;t find your address
          then please{' '}
          <Link href={process.env.NEXT_PUBLIC_HELP_LINK} target="_blank">
            contact us
          </Link>
        </>
      }
    />
  );
}

function FindAddressButton({
  onTrigger,
  disabled,
  loading,
}: {
  onTrigger: () => void;
  disabled: boolean;
  loading: boolean;
}) {
  const handleOnKeyDown = async (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onTrigger();
    }
  };

  return (
    <Button
      onClick={onTrigger}
      onKeyDown={handleOnKeyDown}
      color="primary"
      size="large"
      aria-label="Find address"
      fullWidth
      md={{
        size: 'medium',
      }}
      startIcon={<SearchIcon />}
      disabled={disabled || loading}
      loading={loading}
      loadingLabel={
        <Visibility display="none" md={{ display: 'block' }}>
          Loading...
        </Visibility>
      }
    >
      <Visibility display="none" md={{ display: 'block' }}>
        Find address
      </Visibility>
    </Button>
  );
}

function SearchIcon() {
  const {
    color: {
      base: { main: buttonIconColour },
    },
  } = useCoralTheme();
  return <IconMagnifyingGlass color={buttonIconColour} size={16} />;
}
