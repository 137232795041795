import { useAccountNumberList } from '@/hooks/accounts/useAccountNumberList';

const MAX_ALLOWED_ACCOUNTS = 10;

export const useIsPortfolioAccount = (shouldFetch = true) => {
  const { data, isError, isSuccess, isLoading } =
    useAccountNumberList(shouldFetch);

  const accountCount = isSuccess && data ? data.length : 0;
  const isPortfolioAccount = accountCount > 1;
  const isAllowedPortfolioSize = accountCount <= MAX_ALLOWED_ACCOUNTS;

  return {
    isPortfolioAccount,
    isAllowedPortfolioSize,
    accountCount,
    isError,
    isLoading,
  };
};
