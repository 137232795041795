import { subDays, addMonths, format } from 'date-fns';

import {
  formatFullDateShortMonth,
  formatDayOfTheWeek,
} from './formatters/date';

export const getDateNDaysAgo = (numberOfDaysAgo: number) => {
  const rawDate = subDays(new Date(), numberOfDaysAgo);
  return formatFullDateShortMonth(rawDate);
};

// This function returns the string day of the week (e.g. 'Wednesday')
export const getDayOfTheWeekNDaysAgo = (numberOfDaysAgo: number) => {
  const rawDate = subDays(new Date(), numberOfDaysAgo);
  return formatDayOfTheWeek(rawDate);
};

export const makeNumberTwoDigits = (number: number) => {
  return String(number).padStart(2, '0');
};

// This function returns the next nth day of the month, where n is the dayOfMonth parameter. This only works if dayOfMonth is any number between 1 and 28, inclusive. Any higher than 28 and it will get weird.
export const getNextNthDayOfTheMonth = (
  dayOfMonth: number,
  dateToStartFrom: Date,
  doNotAddMonth: boolean = false
) => {
  // Get the day, month and year of dateToStartFrom
  const currentDayOfMonth = parseInt(format(dateToStartFrom, 'd'));
  const currentMonth = parseInt(format(dateToStartFrom, 'M'));
  const currentYear = parseInt(format(dateToStartFrom, 'yyyy'));

  const needToAddMonth = dayOfMonth < currentDayOfMonth;

  // this is a very particular edge case for the end of the annual billing period
  // because we can't allow the next payment to be in a month that's passed the end of the billing period
  // if the requested day of the month is before the first possible Instalment, then we just use the first possible Instalment
  // otherwise we would need to add a month, and we can't do that
  // note = dateToStartFrom is the first possible Instalment, and is already 14 days in the future
  if (needToAddMonth && doNotAddMonth) {
    return dateToStartFrom;
  }

  // Use the makeNumberTwoDigits function to ensure the month and day are always two digits, so they're padded with a 0 if necessary. Not doing this breaks the page on webkit browsers.
  return needToAddMonth
    ? addMonths(
        new Date(
          `${currentYear}-${makeNumberTwoDigits(
            currentMonth
          )}-${makeNumberTwoDigits(dayOfMonth)}`
        ),
        1
      )
    : new Date(
        `${currentYear}-${makeNumberTwoDigits(
          currentMonth
        )}-${makeNumberTwoDigits(dayOfMonth)}`
      );
};

export const currentDateIsBetweenStartAndEndDates = ({
  startDate,
  endDate,
}: {
  startDate?: string | null;
  endDate?: string | null;
}) => {
  // If there are no start or end dates, this function will return true
  if (!startDate && !endDate) return true;

  const currentDateISO = new Date();

  if (startDate && endDate) {
    const startDateISO = new Date(startDate);
    const endDateISO = new Date(endDate);

    return currentDateISO >= startDateISO && currentDateISO <= endDateISO;
  }

  if (startDate) {
    const startDateISO = new Date(startDate);

    return currentDateISO >= startDateISO;
  }

  if (endDate) {
    const endDateISO = new Date(endDate);

    return currentDateISO <= endDateISO;
  }
};
