// TODO: merge this with the AllPaymentPlanInstalment hook once I've figured out what the hook is actually for, and how to combine the two without breaking anything.
import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { DIRECT_DEBIT_SCHEDULE_CHOICES } from '@/consts/paymentFrequency';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { payInFullInstalmentsSchema } from '../validations';

import PayInFullInstalments from './PayInFullInstalments.graphql';

export const usePayInFullInstalments = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['payInFullInstalments'],
    query: PayInFullInstalments,
    select: (data: any) => data.proposedDirectDebitPaymentSchedule,
    variables: {
      accountNumber,
      scheduleType: DIRECT_DEBIT_SCHEDULE_CHOICES.BIANNUAL_INSTALMENTS,
    },
    validateFn(response) {
      return payInFullInstalmentsSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated && accountNumber),
  });
};
