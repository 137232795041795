import { useEffect } from 'react';
import { Card, Stack, Tooltip, Typography } from '@krakentech/coral';

import PaymentForecastTable, {
  PaymentForecastTableProps,
} from '@/components/billingAndPayment/PaymentForecastCard/components/PaymentForecastTable';
import PageError from '@/components/PageError';
import { Body1Skeleton, H4Skeleton } from '@/components/Skeletons';
import { usePaymentSchedulesContext } from '@/context';
import { useLedgers } from '@/hooks/billsAndPayments/useLedgers';
import { usePaymentForecast } from '@/hooks/billsAndPayments/usePaymentForecast';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';

const PaymentForecastCard = () => {
  const { setNumberOfDesktopColumns } = usePaymentSchedulesContext();

  const {
    isLoading: upcomingPaymentsLoading,
    isError: upcomingPaymentsError,
    data: upcomingPayments,
  } = usePaymentForecast({
    first: 12,
  });
  const { isLoading: ledgersLoading, isError: ledgersError } = useLedgers();

  // If we're showing any data, or the loading or error state, make sure there are two columns on the desktop view of the page. Otherwise, it's just 1
  useEffect(() => {
    if (
      upcomingPaymentsLoading ||
      upcomingPaymentsError ||
      upcomingPayments?.length
    ) {
      setNumberOfDesktopColumns(2);
    } else {
      setNumberOfDesktopColumns(1);
    }
  }, [
    upcomingPayments,
    upcomingPaymentsError,
    upcomingPaymentsLoading,
    setNumberOfDesktopColumns,
  ]);

  const {
    isLoading: hasActiveWaterMeterLoading,
    isError: hasActiveWaterMeterError,
    data: activeWaterMeterCount,
  } = useHasActiveWaterMeter();

  if (upcomingPaymentsLoading || ledgersLoading) {
    return <LoadingState />;
  }

  if (upcomingPaymentsError || ledgersError) {
    return <PageError />;
  }

  if (!upcomingPayments || !upcomingPayments.length) {
    return null;
  }

  const getTooltipText = () => {
    return `This lists up to 12 of your upcoming payments.${
      activeWaterMeterCount
        ? " Remember, your payments are reviewed regularly to ensure you're paying enough to cover your usage."
        : ''
    } Please note that payments are displayed up until their due date.`;
  };

  const tableProps: PaymentForecastTableProps = {
    upcomingPayments: upcomingPayments,
  };

  return (
    <Card>
      <Stack direction="vertical" gap="md">
        <Stack gap="xs">
          <Typography variant="h2">Upcoming payments</Typography>

          {!hasActiveWaterMeterError && !hasActiveWaterMeterLoading && (
            <Tooltip title={getTooltipText()} theme="dark" />
          )}
        </Stack>

        <PaymentForecastTable {...tableProps} />
      </Stack>
    </Card>
  );
};

const LoadingState = () => (
  <Card>
    <Stack direction="vertical" gap="md">
      <Stack gap="xs">
        <H4Skeleton />
      </Stack>

      {Array.from({ length: 2 }).map((_, i) => (
        <Stack key={i} fullWidth justifyContent="space-between">
          <Body1Skeleton />
          <Body1Skeleton />
        </Stack>
      ))}
    </Stack>
  </Card>
);

export default PaymentForecastCard;
