import { addDays, subMonths } from 'date-fns';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { MeterReading } from '@/components/usage/MeterReadingsList/index.types';
import { METER_READING_SOURCES } from '@/consts/meterReadingSources';
import { getMaxDaysInFutureForHomeMove } from '@/consts/moveHome';
import {
  MIN_CHARACTER_LENGTH,
  MIN_CHARACTER_LENGTH_ERROR_MESSAGE,
} from '@/consts/passwordValidation';
import {
  requiredEmailAddressValidationMessage,
  requiredNewPasswordValidationMessage,
  validEmailAddressValidationMessage,
} from '@/consts/validation';
import { makeMeterReadingCorrectLength } from '@/utils/formatters/valueCubicMetres';
import { isFieldEmpty } from '@/utils/validators/general';

import {
  validateDay,
  validateMonth,
  validateYear,
  dateTooLate,
  fieldIsEmpty,
  dateIsEmpty,
} from './date';

export const validateDateOfBirth = Yup.object()
  .shape({
    year: Yup.string(),
    month: Yup.string(),
    day: Yup.string(),
  })
  .test(
    'dateOfBirth',
    'Please enter your date of birth',
    (val) => !dateIsEmpty(val.year, val.month, val.day)
  )
  .test(
    'dayOfBirth',
    'Please enter a value into all fields',
    (val) => !fieldIsEmpty(val.day)
  )
  .test('dayOfBirth', 'Please enter a valid date', (val) =>
    validateDay({
      year: val.year,
      month: val.month,
      day: val.day,
    })
  )
  .test('monthOfBirth', 'Please enter a valid date', (val) =>
    validateMonth(val.month)
  )
  .test(
    'dateOfBirth',
    'Please enter a date of birth that is in the past',
    (val) => !dateTooLate(val?.year, val?.month, val?.day)
  )
  .test('yearOfBirth', 'Please enter a valid date', (val) =>
    validateYear({
      year: val?.year,
      month: val?.month,
      day: val?.day,
    })
  );

export const validateEmail = Yup.string()
  .email(validEmailAddressValidationMessage)
  .required(requiredEmailAddressValidationMessage);

// If the user enters a reading that is lower than the last reading, we need to show a form error. However, if the last reading was an estimate, we don't want to show this error. This is because they could be correcting an incorrect estimate.
export const getValueCubicMetresValidationObject = (
  numberOfDigits: number,
  lastReading: MeterReading
) => {
  const validationObject = Yup.number()
    .required('Please enter your meter reading')
    .test(
      'length',
      `Please enter the ${numberOfDigits} digits that are black or before the decimal place`,
      (val) =>
        makeMeterReadingCorrectLength(val, numberOfDigits).length <=
        numberOfDigits
    );

  if (lastReading.source === METER_READING_SOURCES.ESTIMATE) {
    return validationObject;
  } else {
    return validationObject.min(
      lastReading.valueCubicMetres,
      'Please enter a reading higher than or equal to the last reading'
    );
  }
};

const mobileRegex = /^(07\d{9}|\+447\d{9})$/; // UK mobile numbers (starting with 07 or +447 followed by 9 digits)
const landlineRegex = /^(0[1-2]\d{9}|\+44[1-2]\d{9})$/; // UK landline numbers starting with 01 or 02 (or +44 followed by 1 or 2) followed by 9 digits

export const isMobileNumber = (value?: string): boolean => {
  if (!value) return true; // Allow empty field; other validation ensures either mobile or landline is required.
  const sanitizedValue = value.replace(/\s+/g, '');
  return mobileRegex.test(sanitizedValue);
};

export const isLandlineNumber = (value?: string): boolean => {
  if (!value) return true; // Allow empty field; other validation ensures either mobile or landline is required.
  const sanitizedValue = value.replace(/\s+/g, '');
  return landlineRegex.test(sanitizedValue);
};

export const mobileValidation = {
  mobile: Yup.string()
    .test('is-mobile', 'Please enter a valid mobile number', isMobileNumber)
    .optional()
    .nullable()
    .test(
      'mobileOrLandline',
      'Please enter a mobile or landline number',
      (value, context) =>
        !isFieldEmpty(value) || !isFieldEmpty(context.parent.landline)
    ),
};

export const landlineValidation = {
  landline: Yup.string()
    .test(
      'is-landline',
      'Please enter a valid landline number',
      isLandlineNumber
    )
    .optional()
    .nullable()
    .test(
      'mobileOrLandline',
      'Please enter a mobile or landline number',
      (value, context) =>
        !isFieldEmpty(value) || !isFieldEmpty(context.parent.mobile)
    ),
};

export const validatePersonalDetailsForm = Yup.object().shape({
  ...mobileValidation,
  ...landlineValidation,
  givenName: Yup.string().required('Please enter your first name'),
  middleName: Yup.string().optional().nullable(),
  familyName: Yup.string().required('Please enter your last name'),
  email: validateEmail,
  pronouns: Yup.string()
    .max(64, 'Pronouns must be at most 64 characters')
    .optional()
    .nullable(),
  homeOwnershipStatus: Yup.object().optional().nullable(),
  employmentStatus: Yup.object().optional().nullable(),

  title: Yup.object().optional().nullable(),
  dateOfBirth: validateDateOfBirth,
});

export const getMoveDateValidationObject = ({
  errorMessageWithContactLink,
}: {
  errorMessageWithContactLink: (message: string) => void;
}) => {
  const today = new Date();
  const maxMonthsFromToday = 18;
  const maxDaysFromToday = getMaxDaysInFutureForHomeMove();

  // TODO: subject to change because maxDaysFromToday will be dynamic based on the BE which is not yet available
  const maxDaysFromTodayMessage = `Please enter a date no more than ${maxDaysFromToday} days from today`;

  const moveDataValidationObject = Yup.date()
    .required('Please enter a valid date')
    .min(subMonths(today, maxMonthsFromToday), () =>
      errorMessageWithContactLink(
        'Please enter a date less than 18 months ago or'
      )
    )
    .max(addDays(today, maxDaysFromToday), maxDaysFromTodayMessage);

  return moveDataValidationObject;
};

export const getAddressValidationObject = (message: string) => {
  return Yup.object().test(
    'is-empty',
    message,
    (value) => value && Object.values(value).some((val) => val)
  );
};

export const stwPasswordValidationSchema = Yup.string()
  .min(MIN_CHARACTER_LENGTH, MIN_CHARACTER_LENGTH_ERROR_MESSAGE)
  .required(requiredNewPasswordValidationMessage)
  .matches(
    /[!@#$%^&*(),.?":{}|<>]/,
    'Your password must contain at least 1 special character'
  )
  .matches(/[0-9]/, 'Your password must contain at least 1 number')
  .matches(/[a-z]/, 'Your password must contain at least 1 lowercase character')
  .matches(
    /[A-Z]/,
    'Your password must contain at least 1 uppercase character'
  );
