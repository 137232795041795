import { Stack } from '@krakentech/coral';

import PIITypography from '@/components/PII/PIITypography';
import { UpcomingPayment } from '@/types/paymentSchedules';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';

export type PaymentForecastTableProps = {
  upcomingPayments: UpcomingPayment[];
};

const PaymentForecastTable = ({
  upcomingPayments,
}: PaymentForecastTableProps) => {
  return (
    <>
      {upcomingPayments?.map(
        ({ node: { date, amount } }: UpcomingPayment, i: number) => (
          <Stack key={i} fullWidth justifyContent="space-between">
            <PIITypography>
              {formatFullDateShortMonth(new Date(date))}
            </PIITypography>
            <PIITypography>
              <strong>{formatCurrency(amount)}</strong>
            </PIITypography>
          </Stack>
        )
      )}
    </>
  );
};

export default PaymentForecastTable;
