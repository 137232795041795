import { ReactNode } from 'react';
import { Dialog, Stack, Button } from '@krakentech/coral';

import PIITypography from '@/components/PII/PIITypography';

const ExplanationDialog = ({
  title,
  isOpen,
  onClose,
  children,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}) => (
  <Dialog onClose={onClose} open={isOpen} ariaLabelledBy="sourceExplanation">
    <Stack direction="vertical" gap="lg" component="section">
      <Stack direction="vertical" gap="md">
        <PIITypography variant="h1">{title}</PIITypography>

        {children}
      </Stack>

      <Button fullWidth size="medium" onClick={onClose}>
        Close
      </Button>
    </Stack>
  </Dialog>
);

export default ExplanationDialog;
