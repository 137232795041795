import { useState } from 'react';
import Link from 'next/link';
import { Button, Typography, Stack, Visibility } from '@krakentech/coral';

import ExplanationDialog from '@/components/ExplanationDialog/ExplanationDialog';
import PIITypography from '@/components/PII/PIITypography';
import Table from '@/components/Table';
import { METER_READING_SOURCE_LABELS } from '@/consts/meterReadingSources';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { makeMeterReadingCorrectLength } from '@/utils/formatters/valueCubicMetres';
import { INTERNAL_PATHS } from '@/utils/urls';

import { MeterReadingSource, MeterReadingListProps } from './index.types';

const MeterReadingsList = ({
  readings,
  numberOfMeterDigits,
  meterId,
}: MeterReadingListProps) => {
  const [openDialog, setOpenDialog] = useState<MeterReadingSource | null>(null);

  return (
    <>
      <Visibility display="none" md={{ display: 'block' }}>
        <Table
          columns={[
            {
              name: 'Date taken',
              width: 20,
            },
            {
              name: 'Type',
              width: 70,
            },
            {
              name: 'Reading',
              width: 10,
            },
          ]}
          data={readings.map(({ node }) => ({
            readingDate: node.readingDate ? (
              <PIITypography textWrap="nowrap">
                {formatFullDateShortMonth(node.readingDate)}
              </PIITypography>
            ) : (
              ''
            ),
            type: node.source ? (
              <Button
                variant="link"
                color="primary"
                onClick={() =>
                  setOpenDialog(
                    METER_READING_SOURCE_LABELS[
                      node.source
                    ] as MeterReadingSource
                  )
                }
                id="sourceExplanation"
              >
                {METER_READING_SOURCE_LABELS[node.source]}
              </Button>
            ) : (
              ''
            ),
            valueCubicMetres:
              typeof node.valueCubicMetres !== 'undefined' ? (
                <PIITypography>
                  <strong>
                    {makeMeterReadingCorrectLength(
                      node.valueCubicMetres,
                      numberOfMeterDigits
                    )}
                  </strong>
                </PIITypography>
              ) : (
                ''
              ),
          }))}
        />
      </Visibility>

      <Visibility md={{ display: 'none' }}>
        <Table
          columns={[
            {
              name: 'Date taken',
              width: 90,
            },
            {
              name: 'Reading',
              width: 10,
            },
          ]}
          data={readings.map(({ node }) => ({
            readingDate:
              node.readingDate && node.source ? (
                <Stack direction="vertical" gap="none" alignItems="flex-start">
                  <PIITypography>
                    {formatFullDateShortMonth(node.readingDate)}
                  </PIITypography>
                  <Button
                    variant="link"
                    color="primary"
                    onClick={() =>
                      setOpenDialog(
                        METER_READING_SOURCE_LABELS[
                          node.source
                        ] as MeterReadingSource
                      )
                    }
                    id="sourceExplanation"
                  >
                    {METER_READING_SOURCE_LABELS[node.source]}{' '}
                  </Button>
                </Stack>
              ) : (
                ''
              ),
            valueCubicMetres:
              typeof node.valueCubicMetres !== 'undefined' ? (
                <PIITypography>
                  <strong>
                    {makeMeterReadingCorrectLength(
                      node.valueCubicMetres,
                      numberOfMeterDigits
                    )}
                  </strong>
                </PIITypography>
              ) : (
                ''
              ),
          }))}
        />
      </Visibility>

      <ExplanationDialog
        isOpen={
          openDialog === METER_READING_SOURCE_LABELS.CUSTOMER ||
          openDialog === METER_READING_SOURCE_LABELS.OPS
        }
        onClose={() => setOpenDialog(null)}
        title="Your reading"
      >
        <Typography>
          These are meter readings you entered online or gave to us over the
          phone or in an email.
        </Typography>
      </ExplanationDialog>

      <ExplanationDialog
        isOpen={openDialog === METER_READING_SOURCE_LABELS.METER_READER}
        onClose={() => setOpenDialog(null)}
        title="Our reading"
      >
        <Typography>
          These are meter readings we have taken from your meter.
        </Typography>
      </ExplanationDialog>

      <ExplanationDialog
        isOpen={openDialog === METER_READING_SOURCE_LABELS.ESTIMATE}
        onClose={() => setOpenDialog(null)}
        title="Estimate"
      >
        <Stack direction="vertical" gap="sm">
          <Typography>
            If we don&apos;t receive a meter reading we estimate what your
            reading is.
          </Typography>
          <Typography>
            If you want to make sure you&apos;re being billed to the correct
            amount{' '}
            <Link
              href={INTERNAL_PATHS.SUBMIT_METER_READING.createPath({
                meterId,
              })}
            >
              enter a meter reading
            </Link>{' '}
            and we will update your account.
          </Typography>
        </Stack>
      </ExplanationDialog>
    </>
  );
};

export default MeterReadingsList;
