import { Card, Stack, Typography } from '@krakentech/coral';
import { FormikSwitch } from '@krakentech/coral-formik';

import MutedText from '@/components/MutedText';
import { MARKETING_PREFERENCE_OPTIONS } from '@/consts/marketingPreferenceOptions';
import { useCommsPreferencesContext } from '@/context';
import { useUpdateCommsPreferences } from '@/hooks/accounts/useUpdateCommsPreferences';
import { useMovingOutStatus } from '@/hooks/moveHome/useMovingOutStatus';
import { SwitchOverride } from '@/styles/Overrides.styled';
import { isStw } from '@/utils/environment';

const MarketingPrefefencesSection = () => {
  const { mutationLoading, setMutationLoading } = useCommsPreferencesContext();
  const { mutate } = useUpdateCommsPreferences();
  const { hasMovedOut } = useMovingOutStatus();

  const submitUpdateCommsPreference = (
    fieldName: string,
    fieldValue: boolean
  ) => {
    // Disable all the inputs
    setMutationLoading(true);

    mutate({
      input: {
        [fieldName]: fieldValue,
      },
    });
  };

  return (
    <Card>
      <Stack direction="vertical" gap="md">
        <Stack direction="vertical" gap="xs">
          <Typography variant="h2">
            {process.env.NEXT_PUBLIC_COMPANY_NAME} communications
          </Typography>
          <Typography variant="body2" color="dark">
            As part of delivering your water services, we must send you
            communications that relate to your water services. On occasion, we
            may want to contact you with non-essential messages, but you have
            choices about these communications.
          </Typography>
        </Stack>

        <Stack direction="vertical" gap="xxs">
          <SwitchOverride>
            <FormikSwitch
              name={MARKETING_PREFERENCE_OPTIONS.MARKETING}
              disabled={hasMovedOut || mutationLoading}
              onChange={(_, fieldValue) =>
                submitUpdateCommsPreference(
                  MARKETING_PREFERENCE_OPTIONS.MARKETING,
                  fieldValue
                )
              }
            >
              <Stack alignItems="center">
                <FormikSwitch.Label label="News, updates and information from us" />
                <FormikSwitch.Input />
              </Stack>
            </FormikSwitch>
          </SwitchOverride>
          <MutedText>
            This is anything that is not an essential message about your water
            supply. Examples of these may include: invitations to take part in
            new trials, water-saving initiatives, promotions, and the latest
            news.
          </MutedText>
        </Stack>
        {!isStw() && (
          <SwitchOverride>
            <FormikSwitch
              name={MARKETING_PREFERENCE_OPTIONS.PLUMBING_OFFER}
              disabled={hasMovedOut || mutationLoading}
              onChange={(_, fieldValue) =>
                submitUpdateCommsPreference(
                  MARKETING_PREFERENCE_OPTIONS.PLUMBING_OFFER,
                  fieldValue
                )
              }
            >
              <Stack alignItems="center">
                <FormikSwitch.Label label="Information about plumbing & drainage cover" />
                <FormikSwitch.Input />
              </Stack>
            </FormikSwitch>
          </SwitchOverride>
        )}
      </Stack>
    </Card>
  );
};

export default MarketingPrefefencesSection;
