/**
 * Intended for use inside a useEffect as server-rendered applications do not immediately have access to the window
 */
export const getLocalStorageItem = (key: string): string | null | undefined => {
  return typeof window !== 'undefined' ? window.localStorage[key] : null;
};

export const setLocalStorageItem = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
};

export const removeLocalStorageItem = (key: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
};

export enum LOCAL_STORAGE_ITEMS {
  // used to set the user's selected cookie preference, e.g. 'everything' or 'analytics', etc.
  COOKIE_PREFERENCE = 'cookiePreference',
  POST_LOGIN_PATHNAME = 'postLoginPathname',
  POST_LOGIN_QUERY = 'postLoginQuery',
}
