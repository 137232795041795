import { format } from 'date-fns';

export const formatFullDateShortMonth = (date: Date) => {
  return format(new Date(date), 'd MMM yyyy');
};

export const formatFullDateLongMonth = (date: Date) => {
  return format(new Date(date), 'd MMMM yyyy');
};

export const formatDateShortMonthYear = (date: Date) => {
  return format(new Date(date), 'MMM yyyy');
};

export const formatDateMonthYear = (date: Date) => {
  return format(new Date(date), 'MMMM yyyy');
};

export const formatDateDayAndMonth = (date: Date) => {
  return format(new Date(date), 'do MMMM');
};

export const makeDateKrakenFriendly = (date: string | Date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const formatDayOfTheWeek = (date: Date) => {
  return format(new Date(date), 'eeee');
};
